import AbTestProvider from '@root/core/src/contexts/ab-test/ab-test-provider';
import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import AnalyticsService from '@root/core/src/services/analytics-service';
import AuthController from '@root/auth/src/components/auth-controller';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import ExperienceProvider from '@root/core/src/contexts/experience/experience-provider';
import PropTypes from '@root/vendor/prop-types';
import ProtectedApp from '@root/renters.joinroot.com/src/components/protected-app';
import PublicRouter from '@root/renters.joinroot.com/src/components/public-router';
import SceneLoader from '@root/core/src/components/scene-loader';
import ScrollToTop from '@root/core/src/components/scroll-to-top';
import TrackVisitAttributionController from '@root/attribution/src/components/track-visit-attribution-controller';
import TrackingService from '@root/attribution/src/services/tracking-service';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useSaveTouchAttributionEffect from '@root/attribution/src/hooks/use-save-touch-attribution-effect';
import { Suspense, useEffect } from '@root/vendor/react';

const RENTERS_WEB_APP_ANALYTICS_PREFIX = 'RENTERS_WEB_APP';
const RENTERS_APP_DOMAIN = 'renters.joinroot.com';

function App({ routerComponent: Router }) {
  useAnalytics('APP_ENTRY');
  useSaveTouchAttributionEffect();

  useEffect(() => {
    TrackingService.fireDistinctIdSetEvent(AnalyticsService.getDistinctId());
  }, []);

  return (
    <Router>
      <TrackVisitAttributionController />
      <ScrollToTop>
        <Suspense fallback={<SceneLoader />}>
          <AuthController
            ProtectedApp={ProtectedApp}
            PublicRouter={PublicRouter}
            SceneLoader={SceneLoader}
          />
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

App.propTypes = {
  routerComponent: PropTypes.func.isRequired,
};

export default function AppEntry({
  initialAbTests = {}, initialExperiences = {}, routerComponent,
}) {
  return (
    <ErrorBoundary>
      <AbTestProvider initialAbTests={initialAbTests}>
        <ExperienceProvider initialExperiences={initialExperiences}>
          <AnalyticsProvider eventPrefix={RENTERS_WEB_APP_ANALYTICS_PREFIX}>
            <App routerComponent={routerComponent} />
          </AnalyticsProvider>
        </ExperienceProvider>
      </AbTestProvider>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  initialAbTests: PropTypes.object,
  initialExperiences: PropTypes.object,
  routerComponent: PropTypes.func.isRequired,
};

export { RENTERS_APP_DOMAIN };
