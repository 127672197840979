/* eslint root/prevent-use-of-window-location: 0 */

export default class WindowLocationService {
  static pathname() {
    return window?.location?.pathname;
  }

  static hostname() {
    return window?.location?.hostname;
  }
}
