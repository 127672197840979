import environment from '@root/core/src/utils/environment';

export const TEAMS = {
  MARKETING_TECHNOLOGY: 'marketing_technology',
  NEW_CHANNELS: 'new_channels',
  CUSTOMER_ENGAGEMENT: 'customer_engagement',
};

const TEAM_TO_PATHNAMES = {
  [TEAMS.NEW_CHANNELS]: [
    '/affiliates',
    '/affiliates/application',
  ],
};

const HOSTNAMES = {
  JOINROOT: 'www.joinroot.com',
};

export default function mapUrlToEngTeam({ hostname, pathname }) {
  const trimmedPathname = _trimExtraneousSlashes(pathname);

  if (hostname === HOSTNAMES.JOINROOT) {
    return _findTeamForJoinRoot({
      hostname,
      pathname: trimmedPathname,
    });
  }

  return _findTeamForOtherHostname();
}

function _findTeamForJoinRoot({ pathname }) {
  const trimmedPathname = _trimExtraneousSlashes(pathname);
  const teamsWithPathnames = Object.keys(TEAM_TO_PATHNAMES);
  let matchingTeam = teamsWithPathnames.find((groupName) => TEAM_TO_PATHNAMES[groupName].includes(trimmedPathname));
  matchingTeam = matchingTeam || TEAMS.MARKETING_TECHNOLOGY;

  return matchingTeam;
}

function _findTeamForOtherHostname() {
  return environment.sentryTeam || TEAMS.CUSTOMER_ENGAGEMENT;
}

function _trimExtraneousSlashes(pathname) {
  const pathnameLastCharacter = pathname.charAt(pathname.length - 1);

  if (pathnameLastCharacter === '/') {
    const pathnameExcludingLastCharacter = pathname.substring(0, pathname.length - 1);
    return _trimExtraneousSlashes(pathnameExcludingLastCharacter);
  }

  return pathname;
}
