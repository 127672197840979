import Limit from '@root/renters.joinroot.com/src/models/limit';

export default class Coverage {
  static IGNORED_SYMBOLS = ['pp_jewelry', 'pp_elec'];
  static PERSONAL_PROPERTY = 'Personal Property';
  static PERSONAL_LIABILITY = 'Personal Liability';
  static LOSS_OF_USE = 'Loss of Use';
  static MEDICAL_PAYMENTS_TO_OTHERS = 'Medical Payments to Others';
  static EARTHQUAKE = 'Earthquake';
  static DEDUCTIBLE = 'Deductible';
  static DEDUCTIBLE_TEXT = 'If something happens to your stuff, this is the amount we’ll deduct from the claims payout before your coverage kicks in.';

  static DISPLAY_NAMES = {
    'Personal Property': 'Personal items',
    'Personal Liability': 'Liability',
    'Loss of Use': 'Loss of use',
    'Medical Payments to Others': 'Medical expenses for others',
    Earthquake: 'Earthquake deductible',
  }

  static DISPLAY_TEXT = {
    'Personal Property': 'If something were to happen to your stuff (like theft, a fire, or specific water damage), this is the total amount we’d pay to replace those items.',
    'Personal Liability': 'If someone gets injured on your property and were to take legal action against you, we’ll pay up to this amount to help cover those costs.',
    'Loss of Use': 'If something happened that made your home unliveable, this is the amount we’d pay for hotel arrangements or food.',
    'Medical Payments to Others': 'The amount we’ll pay for medical expenses for guests injured on the property.',
    Earthquake: 'Coverage for damage caused by an earthquake. This deductible is what you’ll pay before your coverage kicks in to cover earthquake damage.',
  }

  constructor({
    limit, deductible, deductiblePercentage, name, symbol, premiumInCents, declined,
  } = {}) {
    this.limit = limit;
    this.deductible = deductible;
    this.deductiblePercentage = deductiblePercentage;
    this.name = name;
    this.symbol = symbol;
    this.premiumInCents = premiumInCents;
    this.declined = declined;
  }

  static buildFromData({
    limits, name, symbol, premiumInCents, declined,
  } = {}) {
    const liabilityLimit = limits.find((limit) => limit.kind === Limit.LIABILITY);
    const deductible = limits.find((limit) => limit.kind === Limit.DEDUCTIBLE);
    const deductiblePercentage = limits.find((limit) => limit.kind === Limit.DEDUCTIBLE_PERCENTAGE);

    return Object.assign(
      new Coverage(),
      {
        limit: liabilityLimit ? Limit.buildFromData(liabilityLimit) : null,
        deductible: deductible ? Limit.buildFromData(deductible) : null,
        deductiblePercentage: deductiblePercentage ? Limit.buildFromData(deductiblePercentage) : null,
        name,
        symbol,
        premiumInCents,
        declined,
      },
    );
  }

  static getCoveragesMappedByName(coverages) {
    const coveragesByName = {};
    for (const coverage of coverages) {
      coveragesByName[coverage.name] = coverage;
    }
    const coverageWithDeductible = coverages.find((coverage) => coverage.deductible);
    coveragesByName[Coverage.DEDUCTIBLE] = coverageWithDeductible?.deductible?.value;
    return coveragesByName;
  }

  get displayName() {
    return Coverage.DISPLAY_NAMES[this.name] || '';
  }

  get displayText() {
    return Coverage.DISPLAY_TEXT[this.name] || '';
  }
}
