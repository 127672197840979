import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { AnimationStyleSheet, StyleSheet } from '@root/core/src/utils/styles';
import { CSSTransition, TransitionGroup } from '@root/vendor/react-transition-group';

export default function SceneAnimator({ location, children }) {
  return (
    <TransitionGroup css={styles.TransitionGroup}>
      <CSSTransition
        classNames={animationClassNames}
        key={location.key}
        timeout={300}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}

SceneAnimator.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  transitionGroup: {
    width: '100%',
    position: 'relative',
    height: 'calc(100vh - 63px)',
  },
});

const animationClassNames = AnimationStyleSheet.createClassNames({
  enter: {
    transform: 'translateX(100%)',
    opacity: '0',
  },
  enterActive: {
    transform: 'translateX(0%)',
    transition: 'all 300ms ease-out',
    opacity: '1',
  },
  enterDone: {
    transform: 'translateX(0%)',
    opacity: '1',
  },
  exit: {
    transform: 'translateX(0%)',
    opacity: '1',
  },
  exitActive: {
    transform: 'translateX(-100%)',
    transition: 'all 300ms ease-out',
    opacity: '0',
  },
  exitDone: {
    transform: 'translateX(-100%)',
    opacity: '0',
  },
});
