import Cookies from '@root/vendor/js-cookie';
import environment from '@root/core/src/utils/environment';

const SESSION_COOKIE_NAME = 'session-activity-tracker';

export default class UserSessionService {
  static updateSessionActivityToNow() {
    if (this.isActiveSession()) {
      this.initializeSessionActivity();
    }
  }

  static initializeSessionActivity() {
    const minutesFutureDate = new Date(new Date().getTime() + environment.minutesTillSessionInactivity * 60 * 1000);

    Cookies.set(SESSION_COOKIE_NAME, Date.now(), {
      expires: minutesFutureDate,
    });
  }

  static isActiveSession() {
    return !!Cookies.get(SESSION_COOKIE_NAME);
  }

  static clear() {
    Cookies.remove(SESSION_COOKIE_NAME);
  }
}
