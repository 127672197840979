import AnalyticsContext from '@root/core/src/contexts/analytics/analytics-context';
import PropTypes from '@root/vendor/prop-types';
import { useState } from '@root/vendor/react';

export default function AnalyticsProvider({ children, eventPrefix }) {
  const [data] = useState({
    eventPrefix,
  });

  return (
    <AnalyticsContext.Provider value={data}>
      {children}
    </AnalyticsContext.Provider>
  );
}

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  eventPrefix: PropTypes.string.isRequired,
};
