export default class ZIndex {
  static HEADER = 2;
  static DROPDOWN_MENU = 2;
  static MODAL = 9999;
  static PACER_BACKGROUND = -1;
  static DATEPICKER_OVERLAY = 2;
  static INPUT = 1;
  static MODAL_OVERLAY_BACKGROUND = 9998;
  static DROPDOWN_OVERLAY_BACKGROUND = 10000;
  static MOBILE_DROPDOWN_MENU = 10001;
  static STICKY_FOOTER = 9;
}
