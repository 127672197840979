import Input, { TextTypes } from '@root/core/src/components/input';
import LoaderButton from '@root/core/src/components/loader-button';
import LoginHeading from '@root/renters.joinroot.com/src/login/components/email-login-heading';
import React, { useCallback, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import RouteLink from '@root/core/src/components/route-link';
import Routes from '@root/renters.joinroot.com/src/login/routes';
import emailReturningUserConfiguration from '@root/renters.joinroot.com/src/api/email-returning-user/email-returning-user-configuration';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { EmailConfirmationModal } from '@root/core/src/components/modals/email-confirmation-modal';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { shouldConfirmEmail } from '@root/core/src/utils/common-email-validator';
import { useHistory } from '@root/vendor/react-router-dom';
import { useLocation } from '@root/core/src/components/route';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

const validations = [
  isRequired('email'),
  isValidEmail('email'),
];

function useHandleSubmit() {
  const history = useHistory();
  const [emailReturningUser] = useImperativeNetworkRequest(emailReturningUserConfiguration);
  const rootId = useRootId();
  const handleSubmit = useCallback(async ({ email }) => {
    await emailReturningUser({
      email,
      rootId,
    });
    history.push(Routes.EMAIL_SENT, {
      email,
    });
  }, [emailReturningUser, history, rootId]);
  return [handleSubmit];
}

export default function LoginWithEmail() {
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const location = useLocation();
  const { trackClick } = useAnalytics('WELCOME_BACK_SCENE');
  const form = useForm({
    initialValues: {
      email: location.state?.email,
    },
    validations,
  });

  const [completeSubmission] = useHandleSubmit();

  const handleSubmission = async ({ email }) => {
    if (!emailConfirmed && shouldConfirmEmail(email)) {
      return setModalVisible(true);
    }

    setSubmitting(true);
    await completeSubmission({ email });
    setSubmitting(false);
  };

  const handleConfirmEmailPressed = async () => {
    setEmailConfirmed(true);
    setModalVisible(false);
    setSubmitting(true);
    await completeSubmission({ email: form.values.email });
    setSubmitting(false);
  };

  const handleEditEmailPressed = () => {
    setEmailConfirmed(true);
    setModalVisible(false);
  };

  return (
    <>
      {modalVisible &&
        <EmailConfirmationModal
          email={form.values.email}
          isShowing={modalVisible}
          onCancel={() => setModalVisible(false)}
          onConfirmEmailPress={handleConfirmEmailPressed}
          onEditEmailPress={handleEditEmailPressed}
        />
      }
      <div css={styles.wrapper}>
        <form
          data-testid={'email-form'}
          onSubmit={form.createSubmitHandler(handleSubmission)}
        >
          <LoginHeading
            heading={'Welcome back.'}
            subtext={'What’s your email address? We’ll pick up where you left off.'}
          />
          <Input
            id={'email'}
            inputType={TextTypes.EMAIL}
            label={'Email'}
            {...form.getFieldProps('email')}
          />
          <LoaderButton
            isLoading={form.submitting || submitting}
            loadingText={'Sending...'}
          >
            Continue
          </LoaderButton>
        </form>
        <div css={styles.linkContainer}>
          {'New to Root? '}
          <RouteLink
            css={styles.link}
            onClick={() => trackClick('SIGN_UP')}
            to={'/'}
          >
            Sign up
          </RouteLink>
        </div>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 86,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    ...Responsive.lg({
      width: '100%',
    }),
  },
  linkContainer: {
    textAlign: 'center',
    marginTop: '30px',
  },
  link: {
    ...Theme.link(Colors.rootOrange()),
  },
});

