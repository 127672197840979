import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function Link({
  'aria-label': ariaLabel, children, disabled, onClick, className, cssOverrides,
}) {
  const handleClick = (event) => {
    event.preventDefault();
    onClick();
  };

  return (
    <button
      aria-label={ariaLabel}
      className={className}
      css={[styles.link, cssOverrides]}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

Link.propTypes = {
  'aria-label': PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cssOverrides: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  link: {
    border: 0,
    padding: 0,
    background: 'none',
    ...Theme.link(),
  },
});
