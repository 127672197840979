import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function loginWithToken(authObject) {
  const { loginToken, otpToken } = authObject;
  return new NetworkRequestConfiguration({
    endpoint: 'login/token',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      loginToken,
      otpToken,
    },
    successCodes: [201, 200],
    errorCodes: [401],
  });
}
