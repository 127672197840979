import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getSignupConfiguration(signupParams) {
  return new NetworkRequestConfiguration({
    endpoint: 'renters/signup',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      signup: signupParams,
    },
    successCodes: [201, 200],
  });
}
