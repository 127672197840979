/* eslint no-console: 0 */
export default class ConsoleService {
  static logMessage(...params) {
    console.log(...params);
  }

  static logError(...params) {
    console.error(...params);
  }
}
