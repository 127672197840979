import Colors from '@root/brand/src/utils/colors';
import DisclaimerLinks from '@root/renters.joinroot.com/src/models/disclaimer-links';
import React from '@root/vendor/react';
import RootSans from '@root/brand/src/assets/fonts/root-sans';
import environment from '@root/core/src/utils/environment';
import { StyleSheet } from '@root/core/src/utils/styles';

const {
  FCRA_DISCLAIMER_URL,
  FRAUD_DISCLAIMER_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  MOBILE_APP_EULA_URL,
  RECAPTCHA_PRIVACY_POLICY_URL,
  RECAPTCHA_TERMS_OF_SERVICE_URL,
} = DisclaimerLinks;

export default function LandingDisclaimer() {
  const toggleOsanoSidebar = () => {
    if (window.Osano.cm.drawerOpen) {
      window.Osano.cm.hideDrawer('osano-cm-dom-info-dialog-open');
    } else {
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
  };

  function renderPrivacyPolicyLink() {
    return (
      <a
        css={styles.link}
        href={PRIVACY_POLICY_URL}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        Privacy Policy
      </a>
    );
  }

  function renderTermsAndConditionsLink() {
    return (
      <a
        css={styles.link}
        href={TERMS_AND_CONDITIONS_URL}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        Terms & Conditions
      </a>
    );
  }

  function renderFcraDisclaimerLink() {
    return (
      <a
        css={styles.link}
        href={FCRA_DISCLAIMER_URL}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        FCRA Disclaimer
      </a>
    );
  }

  function renderFraudDisclaimerLink() {
    return (
      <a
        css={styles.link}
        href={FRAUD_DISCLAIMER_URL}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        Fraud Disclaimer
      </a>
    );
  }

  const cookieManagementLink = () => (
    <a
      css={styles.link}
      onClick={toggleOsanoSidebar}
    >
      Manage Cookies
    </a>
  );

  function renderMobileAppEULALink() {
    return (
      <a
        css={styles.link}
        href={MOBILE_APP_EULA_URL}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        Mobile App EULA
      </a>
    );
  }

  function renderRecaptchaPrivacyPolicyLink() {
    return (
      <a
        css={styles.link}
        href={RECAPTCHA_PRIVACY_POLICY_URL}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        Privacy Policy
      </a>
    );
  }

  function renderRecaptchaTermsOfServiceLink() {
    return (
      <a
        css={styles.link}
        href={RECAPTCHA_TERMS_OF_SERVICE_URL}
        rel={'noopener noreferrer'}
        target={'_blank'}
      >
        Terms of Service
      </a>
    );
  }

  return (
    <div css={styles.content}>
      <p>
        <span>By continuing, you agree to our {renderPrivacyPolicyLink()}, {renderTermsAndConditionsLink()}, {renderFcraDisclaimerLink()}, and {renderMobileAppEULALink()}. Please see our {renderFraudDisclaimerLink()}.</span>
        {environment.osanoEnabled === 'true' &&
          <span>&nbsp;Click here to {cookieManagementLink()}.</span>
        }
      </p>

      <p>
        This site is protected by reCAPTCHA and the Google {renderRecaptchaPrivacyPolicyLink()} and {renderRecaptchaTermsOfServiceLink()} apply.
      </p>
    </div>
  );
}

const styles = StyleSheet.create({
  content: {
    ...RootSans.regular(),
    margin: '30px auto 0',
    width: 'min(600px, 100%)',
    fontSize: '13px',
    lineHeight: '20px',
    color: Colors.gray50(),
    textAlign: 'center',
  },
  link: {
    color: Colors.gray50(),
    cursor: 'pointer',
    textDecoration: 'underline !important',
    ':hover': {
      textDecoration: 'underline',
      color: Colors.gray40(),
    },
  },
});
