import calculateLevenshteinDistance from 'js-levenshtein';

export const COMMON_DOMAINS = ['com', 'edu', 'net', 'org'];
export const COMMON_DOMAIN_NAMES = ['aol', 'gmail', 'hotmail', 'outlook', 'yahoo'];
export const DISTANCE_REQUIRING_CONFIRMATION = 1;

export const shouldConfirmEmail = (email, confirmationDistance = DISTANCE_REQUIRING_CONFIRMATION) => {
  const atFragments = email.toLowerCase().split('@');

  if (!atFragments.length > 1) { return true; }

  const dotFragments = atFragments[1].split('.');
  const domain = dotFragments[dotFragments.length - 1];
  const domainName = dotFragments[dotFragments.length - 2];

  if (!atFragments[0] || !domainName || !domain) { return true; }

  return shouldConfirmSegment(domainName, COMMON_DOMAIN_NAMES, confirmationDistance)
    || shouldConfirmSegment(domain, COMMON_DOMAINS, confirmationDistance);
};

const shouldConfirmSegment = (segment, commonSegments, confirmationDistance) => {
  for (const commonSegment of commonSegments) {
    const levenshteinDistance = calculateLevenshteinDistance(segment, commonSegment);
    if (levenshteinDistance > 0 && levenshteinDistance <= confirmationDistance) {
      return true;
    }
  }

  return false;
};
