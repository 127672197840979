import CookieStorageService from '@root/core/src/services/cookie-storage-service';
import environment from '@root/core/src/utils/environment';
import uuid from '@root/vendor/uuid/v4';

const rootIdSingleton = {};

export class RootIdService {
  static rootIdCookieName = `root_id_${environment.environment}`;

  static read() {
    let rootId = rootIdSingleton.rootId;

    if (rootId) {
      return rootId;
    }

    if (CookieStorageService.has(RootIdService.rootIdCookieName)) {
      rootId = CookieStorageService.get(RootIdService.rootIdCookieName);
    } else {
      rootId = uuid();
    }

    CookieStorageService.set(RootIdService.rootIdCookieName, rootId, { expires: 365 });
    rootIdSingleton.rootId = rootId;

    return rootId;
  }

  static delete() {
    CookieStorageService.unset(RootIdService.rootIdCookieName);
    rootIdSingleton.rootId = undefined;
  }
}
