import CookieStorageService from '@root/core/src/services/cookie-storage-service';
import QueryParamService from '@root/attribution/src/services/query-param-service';

export default class AttributionQueryParamService extends QueryParamService {
  static KEY = 'core.attribution';
  static PARAMETERS = [
    'pid',
    'c',
    'af_c_id',
    'af_adset',
    'af_adset_id',
    'af_ad',
    'af_ad_id',
    'fbclid',
    'gclid',
    'pp_fn',
    'pp_ln',
    'pp_ms',
    'pp_addr1',
    'pp_addr2',
    'pp_cty',
    'pp_st',
    'pp_z',
    'product',
    'pp_reci',
    'pp_redi',
    'pp_vin',
  ];

  static EXPIRATION_OFFSET = 7 * 24 * 60 * 60 * 1000; // 7 day lookback in ms

  static store(href) {
    const now = new Date();
    const touchTimestamp = now.toUTCString();
    const expires = now.getTime() + AttributionQueryParamService.EXPIRATION_OFFSET;
    const url = new URL(href);
    const isValid = this.isValidUrl(url.href);
    const query = isValid
      ? this.parseQuery(url.search)
      : {};
    const currentAttribution = AttributionQueryParamService.read();

    if (!currentAttribution || isValid) {
      CookieStorageService.set(
        AttributionQueryParamService.KEY,
        {
          touchTimestamp,
          sourcePathUrl: url.href,
          expires,
          ...query,
        }
      );
    }
  }
}
