import { Colors, Theme } from '@root/core/src/utils/styles';
import { opacityOverlayOnState } from '@root/core/src/utils/opacity-overlay';

export const BUTTON_BASE_STYLES = {
  ...Theme.button(),
  ...Theme.roundedCorners(),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: 20,
  paddingLeft: 24,
  paddingRight: 24,
  position: 'relative',
  textDecoration: 'none',
  ...opacityOverlayOnState({
    baseStyles: {
      borderRadius: 'inherit',
    },
  }),
  ':hover': {
    textDecoration: 'none',
  },
  ':disabled': {
    pointerEvents: 'none',
    color: Colors.gray50(),
    svg: {
      fill: Colors.gray40(),
    },
  },
  ':not(:disabled)': {
    cursor: 'pointer',
  },
};
