import Button from '@root/core/src/components/button';
import Header from '@root/core/src/components/header';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect } from '@root/vendor/react';
import SceneContent from '@root/core/src/components/scene-content';
import SceneWrapper from '@root/core/src/components/scene-wrapper';
import errorSign from '@root/core/src/assets/error-sign.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export const GENERIC_ERROR_SCREEN_MESSAGE = 'Oops, something went wrong.';
export const ANALYTICS_PREFIX = 'OOPS_ERROR';

export default function Error({
  buttonText, onClick, showHeader, showTimestamp, errorTextSuffix, errorMessage,
}) {
  const { trackEvent } = useAnalytics(ANALYTICS_PREFIX, false);

  useEffect(() => {
    trackEvent('SCREEN', { errorMessage });
  }, [errorMessage, trackEvent]);

  const containerCss = [styles.container];
  if (showHeader) {
    containerCss.push(styles.containerHeaderPadding);
  }

  let timestamp = null;
  if (showTimestamp) {
    timestamp = (
      <p css={styles.timestamp}>
        {new Date().toUTCString()}
      </p>
    );
  }
  let errorTextSuffixLabel = null;
  if (errorTextSuffix) {
    errorTextSuffixLabel = (
      <p css={styles.body}>
        {errorTextSuffix}
      </p>
    );
  }

  return (
    <>
      {showHeader && <Header />}
      <SceneWrapper>
        <SceneContent>
          <div css={styles.centerAlign}>
            <img
              alt={'car accident'}
              css={styles.image}
              src={errorSign}
            />
          </div>
          <p css={styles.title}>{GENERIC_ERROR_SCREEN_MESSAGE}</p>
          <p css={styles.body}>
            We’re sorry, we’ve run into a problem on our end. We’re working to fix the issue as quickly as possible and will let you know once it’s resolved.
          </p>
          {errorTextSuffixLabel}
          {timestamp}
          <Button
            onClick={onClick}
            type={'button'}
          >
            {buttonText}
          </Button>
        </SceneContent>
      </SceneWrapper>
    </>
  );
}

Error.propTypes = {
  buttonText: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  errorTextSuffix: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showHeader: PropTypes.bool.isRequired,
  showTimestamp: PropTypes.bool,
};

const styles = StyleSheet.create({
  body: {
    ...Theme.paragraph1(),
    marginBottom: 10,
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  image: {
    maxWidth: '100%',
  },
  title: {
    ...Theme.heading2(),
  },
  timestamp: {
    textAlign: 'right',
    color: Colors.gray50(),
  },
});
