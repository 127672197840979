import LoginHeading from '@root/renters.joinroot.com/src/login/components/email-login-heading';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import RouteLink from '@root/core/src/components/route-link';
import Routes from '@root/renters.joinroot.com/src/login/routes';
import check from '@root/renters.joinroot.com/src/assets/check.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useLocation } from '@root/core/src/components/route';

export default function LoginEmailLinkSent() {
  const { trackClick } = useAnalytics('CHECK_YOUR_EMAIL_SCENE');
  const location = useLocation();
  const subtext = `We sent a link to ${location.state?.email || 'the email address you gave us'}. Check your email to continue.`;

  return (
    <>
      <div css={styles.wrapper}>
        <LoginHeading
          heading={'Check your email.'}
          icon={check}
          subtext={subtext}
        />
        <RouteLink
          css={styles.link}
          onClick={() => trackClick('TRY_AGAIN')}
          to={Routes.EMAIL_LOGIN}
        >
          Didn’t get your link? Try again.
        </RouteLink>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '25vh',
    ...Responsive.lessThanSm({
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
    ...Responsive.md({
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
  },
  link: {
    ...Theme.link(Colors.rootOrange()),
  },
});
