import PropTypes from '@root/vendor/prop-types';

export default function Row({
  as: RenderComponent = 'div', children, className = '',
}) {
  return (
    <RenderComponent className={`row ${className}`.trim()}>
      {children}
    </RenderComponent>
  );
}

Row.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
};
