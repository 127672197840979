import PropTypes from '@root/vendor/prop-types';
import React, { useEffect } from '@root/vendor/react';
import RestrictedFlowRouter from '@root/renters.joinroot.com/src/components/restricted-flow-router';
import Routes from '@root/renters.joinroot.com/src/routes/protected-routes';
import SceneLoader from '@root/core/src/components/scene-loader';
import { useHistory } from '@root/core/src/components/route';
import { usePolicyContext } from '@root/renters.joinroot.com/src/contexts/policy-context-provider';
import { useQuotesContext } from '@root/renters.joinroot.com/src/contexts/quotes-context-provider';

export function getFlow({ policyContext, quotesContext }) {
  if (policyContext) {
    return Routes.POST_BIND;
  } else if (quotesContext?.hasRequestedQuotes) {
    return Routes.QUOTE;
  } else {
    return Routes.PROFILE;
  }
}

export default function RestrictedFlowEntry({ passProps }) {
  const history = useHistory();

  const {
    context: quotesContext,
    fetched: quotesContextFetched,
  } = useQuotesContext();

  const {
    context: policyContext,
    fetched: policyContextFetched,
  } = usePolicyContext();

  useEffect(() => {
    if (quotesContextFetched && policyContextFetched) {
      const flow = getFlow({
        quotesContext,
        policyContext,
      });

      if (!history.location.pathname.includes(flow)) {
        history.replace(flow);
      }
    }
  });

  if (!policyContextFetched || !quotesContextFetched) {
    return <SceneLoader />;
  }

  const targetFlow = getFlow({
    quotesContext,
    policyContext,
  });

  return (
    !history.location.pathname.includes(targetFlow)
      ? <SceneLoader />
      : (
        <RestrictedFlowRouter
          passProps={{
            ...passProps,
          }}
        />
      )
  );
}

RestrictedFlowEntry.propTypes = {
  passProps: PropTypes.object.isRequired,
};
