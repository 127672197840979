export default class Limit {
  constructor({ kind, value } = {}) {
    this.kind = kind;
    this.value = value;
  }

  static DEDUCTIBLE = 'deductible';
  static DEDUCTIBLE_PERCENTAGE = 'deductible_percentage';
  static LIABILITY = 'liability';

  static buildFromData({ kind, value } = {}) {
    return Object.assign(
      new Limit(),
      {
        kind,
        value,
      },
    );
  }

  get isDeductible() {
    return this.kind === Limit.DEDUCTIBLE;
  }
}
