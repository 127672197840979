import CaretLoader from '@root/core/src/components/caret-loader';
import MapsConsumer from '@root/core/src/components/google-maps-consumer';
import React, { Component, Suspense } from '@root/vendor/react';

export function withGoogleMapsApi(WrappedComponent, LoadingComponent = CaretLoader) {
  class WithGoogleMapsApi extends Component {
    render() {
      return (
        <Suspense fallback={<LoadingComponent />}>
          <MapsConsumer>
            {(google) => (
              <WrappedComponent
                google={google}
                {...this.props}
              />
            )}
          </MapsConsumer>
        </Suspense>
      );
    }
  }

  WithGoogleMapsApi.displayName = `WithGoogleMapsApi(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithGoogleMapsApi;
}
