import PolicyContextModel from '@root/renters.joinroot.com/src/models/policy-context';
import PropTypes from '@root/vendor/prop-types';
import React, { useContext, useEffect, useReducer } from '@root/vendor/react';
import getPolicyContextConfiguration from '@root/renters.joinroot.com/src/api/policy-context/get-policy-context-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';

export const PolicyContext = React.createContext();

const actionTypes = {
  DEPRECATE_POLICY_CONTEXT: 'DEPRECATE_POLICY_CONTEXT',
  SET_POLICY_CONTEXT: 'SET_POLICY_CONTEXT',
};

function reducer(state, action) {
  switch (action.type) {
  case actionTypes.DEPRECATE_POLICY_CONTEXT: {
    return {
      isFetched: false,
    };
  }
  case actionTypes.SET_POLICY_CONTEXT: {
    return {
      context: action.context,
      isFetched: true,
    };
  }
  }
}

export default function PolicyContextProvider({ children, initialContext }) {
  const initialState = initialContext
    ? {
      context: initialContext,
      isFetched: true,
    }
    : {};

  const [state, dispatch] = useReducer(reducer, initialState);

  function dispatchSetPolicyContext(context) {
    dispatch({
      context,
      type: actionTypes.SET_POLICY_CONTEXT,
    });
  }

  function dispatchDeprecatePolicyContext() {
    dispatch({
      type: actionTypes.DEPRECATE_POLICY_CONTEXT,
    });
  }

  return (
    <PolicyContext.Provider
      value={{
        context: state.context,
        fetched: state.isFetched,
        dispatchDeprecatePolicyContext,
        dispatchSetPolicyContext,
      }}
    >
      {children}
    </PolicyContext.Provider>
  );
}

PolicyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialContext: PropTypes.instanceOf(PolicyContextModel),
};

export function usePolicyContext() {
  const [getContext, isRequesting] = useImperativeNetworkRequest(getPolicyContextConfiguration);
  const {
    context,
    dispatchSetPolicyContext,
    fetched,
  } = useContext(PolicyContext);

  useEffect(() => {
    if (!isRequesting && !fetched) {
      getContext().then(({ data: { policyContext } }) => {
        dispatchSetPolicyContext(policyContext
          ? PolicyContextModel.buildFromData(policyContext)
          : null
        );
      });
    }
  }, [context, dispatchSetPolicyContext, fetched, getContext, isRequesting]);

  return {
    context,
    fetched,
  };
}
