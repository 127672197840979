import AuthService from '@root/core/src/services/auth-service';
import environment from '@root/core/src/utils/environment';
import { RootError } from '@root-common/root-errors';

export default class CaptchaService {
  static setup() {
    if (!environment.googleRecaptchaEnabled) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.googleRecaptchaSiteKey}`;
    document.head.appendChild(script);
  }

  static async challenge(action) {
    if (!environment.googleRecaptchaEnabled) {
      if (AuthService.getCurrentUserContext()?.email?.includes('failed_recaptcha_')) { return 'bot-token'; }

      return 'token';
    }

    if (!window.grecaptcha) {
      await new Promise((resolve, reject) => {
        let count = 0;
        const interval = window.setInterval(() => {
          count += 1;
          if (window.grecaptcha) {
            window.clearInterval(interval);
            resolve();
          } else if (count > 10) {
            reject(new RootError({
              message: 'Could not load Captcha',
              name: 'CaptchaServiceError',
            }));
          }
        }, 1000);
      });
    }

    return new Promise((resolve) => {
      window.grecaptcha.enterprise.ready(async () => {
        const token = await window.grecaptcha.enterprise.execute(environment.googleRecaptchaSiteKey, {
          action,
        });
        resolve(token);
      });
    });
  }
}
