import AnalyticsService from '@root/core/src/services/analytics-service';
import AttributionService from '@root/attribution/src/services/attribution-service';
import createTouchAttributionConfiguration from '@root/attribution/src/api/create-touch-attribution/create-touch-attribution-configuration';
import reportVisitEventConfiguration from '@root/attribution/src/api/report-visit-event/report-visit-event-configuration';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-safe-imperative-network-request';
import { useEffect } from '@root/vendor/react';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

export default function useSaveTouchAttributionEffect() {
  const [createTouchAttribution] = useSafeImperativeNetworkRequest(createTouchAttributionConfiguration);
  const [reportVisitEvent] = useSafeImperativeNetworkRequest(reportVisitEventConfiguration);

  const rootId = useRootId();

  useEffect(() => {
    async function trackTouchAttribution() {
      await createTouchAttribution({
        sourcePathUrl: window.document.location.href,
        referrerUrl: document.referrer,
        rootId,
        mixpanelDistinctId: AnalyticsService.getDistinctId(),
      });
      AttributionService.trackVisitEvent({
        rootId,
        reportVisitEvent,
      });
    }
    trackTouchAttribution();
  }, [createTouchAttribution, reportVisitEvent, rootId]);
}
