import Header from '@root/core/src/components/header';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Router from '@root/renters.joinroot.com/src/login/components/router';
import SceneAnimator from '@root/renters.joinroot.com/src/components/scene-animator';
import { withRouter } from '@root/core/src/components/route';

export function Entry({ location, passProps }) {
  return (
    <>
      <Header showProgressBar={false} />
      <SceneAnimator location={location}>
        <Router
          location={location}
          passProps={passProps}
        />
      </SceneAnimator>
    </>
  );
}

export default withRouter(Entry);

Entry.propTypes = {
  location: PropTypes.object.isRequired,
  passProps: PropTypes.object,
};

