import AuthService from '@root/core/src/services/auth-service';
import NetworkRequestController from '@root/core/src/networking/network-request-controller';
import { useEffect, useState } from '@root/vendor/react';

export default function useNetworkRequestBase(networkRequestConfiguration, resultHandlerCallback) {
  const [isRequesting, setIsRequesting] = useState(!!networkRequestConfiguration);
  useEffect(() => {
    let isAborted = false;

    if (!networkRequestConfiguration) {
      setIsRequesting(false);
      return;
    }
    let abortController;
    if (window.AbortController) {
      abortController = new AbortController();
    }

    const authToken = AuthService.getAccessToken();
    const networkRequestController = new NetworkRequestController(abortController, authToken);
    networkRequestController.request(networkRequestConfiguration).then((result) => {
      if (result.isAborted() || isAborted) { return; }

      resultHandlerCallback(result);
      setIsRequesting(false);
    });

    setIsRequesting(true);

    return () => {
      isAborted = true;
      abortController?.abort();
    };
  }, [networkRequestConfiguration, resultHandlerCallback]);

  return isRequesting;
}
