import { useCallback, useRef, useState } from '@root/vendor/react';

export default function useImperativeNetworkRequestBase(requestConfigurationFunction, networkRequestHook) {
  const [request, setRequest] = useState(undefined);
  const resolver = useRef(undefined);

  const networkRequestHandler = useCallback((networkResult) => {
    resolver.current(networkResult);
  }, [resolver]);

  const isRequesting = networkRequestHook(request, networkRequestHandler);

  const triggerRequest = useCallback((requestConfigurationParameters) => {
    return new Promise((resolve) => {
      resolver.current = resolve;
      setRequest(requestConfigurationFunction(requestConfigurationParameters));
    });
  }, [requestConfigurationFunction, setRequest, resolver]);

  return [triggerRequest, isRequesting];
}
