import * as Sentry from '@root/vendor/@sentry/react';
import ExperienceContext from '@root/core/src/contexts/experience/experience-context';
import ExperienceDispatchContext from '@root/core/src/contexts/experience/experience-dispatch-context';
import PropTypes from '@root/vendor/prop-types';
import { useEffect, useReducer } from '@root/vendor/react';

export const SET_EXPERIENCE_ACTION = 'SET_EXPERIENCE';

function experienceReducer(state, action) {
  return action?.type === SET_EXPERIENCE_ACTION ? {
    ...state,
    [action.experienceName]: action.experienceValue,
  } : state;
}

export default function ExperienceProvider({ children, initialExperiences }) {
  const [experiences, dispatch] = useReducer(experienceReducer, initialExperiences);

  useEffect(() => {
    Sentry.setContext('experiences', experiences);
  }, [experiences]);

  return (
    <ExperienceContext.Provider value={experiences}>
      <ExperienceDispatchContext.Provider value={dispatch}>
        {children}
      </ExperienceDispatchContext.Provider>
    </ExperienceContext.Provider>
  );
}

ExperienceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialExperiences: PropTypes.object.isRequired,
};
