import isEmpty from '@root/vendor/lodash/isEmpty';
import { ADDRESS_LINE_ONE_REGEX, SHORT_ZIP_REGEX, isFormatted, isRequired } from '@root/core/src/utils/validators';

export default class RiskAddress {
  constructor({
    address1, address2, city, state, zip,
  } = {}) {
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zip = zip;
  }

  static validations = [
    isRequired('address1', 'Address line 1 is required'),
    isFormatted('address1', ADDRESS_LINE_ONE_REGEX, 'Address must be a number followed by a street name'),
    isRequired('city', 'City is required'),
    isRequired('state', 'State is required'),
    isRequired('zip', 'ZIP code is required'),
    isFormatted('zip', SHORT_ZIP_REGEX, 'ZIP must be 5 digits'),
  ];

  static buildFromData({
    address1, address2, city, state, zip,
  } = {}) {
    return Object.assign(
      new RiskAddress(),
      {
        address1,
        address2,
        city,
        state,
        zip,
      },
    );
  }

  get isValid() {
    return isEmpty(this.errors);
  }

  get errors() {
    const errs = {};
    RiskAddress.validations.forEach((validation) => validation(this, errs));
    return errs;
  }

  serializeForSubmission() {
    return {
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
    };
  }

  get fullAddress() {
    const address2Display = this.address2 ? ` ${this.address2}` : '';
    return `${this.address1}${address2Display}, ${this.city}, ${this.state} ${this.zip}`;
  }

  serializeAsMailingAddress() {
    return {
      line1: this.address1,
      line2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
    };
  }
}
