import ServerUtils from '@root/core/src/api/server-utils';

export async function createWebAttribution({
  sourcePathUrl, referrerUrl, attributionAction, touchTimestamp, mixpanelDistinctId,
}) {
  const response = await ServerUtils.authenticatedPost('/web_api/attributions/web_attribution_parameters', {
    body: {
      sourcePathUrl: encodeURI(sourcePathUrl),
      referrerUrl: encodeURI(referrerUrl),
      attributionAction,
      touchTimestamp,
      mixpanelDistinctId,
    },
    expectedResponse: [201],
    expectedErrorResponses: [422],
  });
  return response;
}

export async function trackVisit({
  sourcePathUrl, referrerUrl, touchTimestamp, mixpanelDistinctId, visitTrackingid,
}) {
  const response = await ServerUtils.authenticatedPut('/web_api/attributions/web_attribution_parameters/track_visit', {
    body: {
      sourcePathUrl: encodeURI(sourcePathUrl),
      referrerUrl: encodeURI(referrerUrl),
      touchTimestamp,
      mixpanelDistinctId,
      visitTrackingid,
    },
    expectedResponse: [200],
    expectedErrorResponses: [422],
  });

  return response;
}

export async function associateVisitsToUser({ visitTrackingid }) {
  const response = await ServerUtils.authenticatedPut('/web_api/attributions/web_attribution_parameters/associate_visits_to_user', {
    body: {
      visitTrackingid,
    },
    expectedResponse: [200],
    expectedErrorResponses: [422],
  });
  return response;
}
