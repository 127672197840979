import Header from '@root/core/src/components/header';
import IneligibleReasons from '@root/renters.joinroot.com/src/models/ineligible-reasons';
import LinkButton from '@root/core/src/components/link-button';
import Market from '@root/core/src/models/market';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';
import { StyleSheet } from '@root/core/src/utils/styles';
import { regular, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';
import { useHistory } from '@root/core/src/components/route';

const IneligibleLanding = () => {
  const history = useHistory();
  const { market, reason } = history.location.state;
  const { DISALLOWED_ZIP, MORATORIUM } = IneligibleReasons;

  let text;
  switch (reason) {
  case DISALLOWED_ZIP:
    text = {
      heading: 'We‘re sorry. We don’t offer renters insurance in your area.',
      description: 'We don’t cover every part of your state yet, but we’re working on it.',
    };
    break;
  case MORATORIUM:
    text = {
      heading: 'We’re sorry. We don’t offer renters insurance in your area right now.',
      description: 'We don’t currently cover your area, but check back soon.',
    };
    break;
  default:
    text = {
      heading: `We’re sorry, we’re not in ${Market.MAPPING[market]} yet.`,
      description: 'Check back soon to see when we come to your state.',
    };
  }

  return (
    <>
      <Header />
      <div css={styles.wrapper}>
        <div css={styles.container}>
          <h1 css={styles.headingText}>{text.heading}</h1>
          <p css={styles.descriptionText}>{text.description}</p>
          <LinkButton
            href={`${environment.websiteBaseUrl}/renters-insurance/`}
            styles={styles.continueButton}
          >Continue
          </LinkButton>
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 15,
    maxWidth: 410,
  },
  continueButton: {
    marginTop: 48,
    height: 60,
  },
  descriptionText: {
    ...regular(),
    fontSize: 17,
    marginTop: 12,
    width: '100%',
  },
  headingText: {
    ...semiBold(),
    lineHeight: '36px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    paddingTop: 60,
  },
});

export default IneligibleLanding;
