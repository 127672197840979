export class Layout {
  static HEADER_HEIGHT = 63;
  static PROGRESS_BAR_HEIGHT = 3;
  static CONTAINER_PADDING = 24;
  static STICKY_FOOTER_HEIGHT = 110;

  static navbarHeight(withProgressBar = true) {
    return withProgressBar ? Layout.HEADER_HEIGHT : Layout.HEADER_HEIGHT - Layout.PROGRESS_BAR_HEIGHT;
  }
}
