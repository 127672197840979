import GoogleMapsService from '@root/core/src/services/google-maps-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef } from '@root/vendor/react';

export function GoogleMap({
  latLng,
  options = {
    zoom: 10,
    disableDefaultUI: true,
  },
  mapsService,
  style = {},
}) {
  const mapRef = useRef();

  useEffect(() => {
    const map = mapsService.createMap(mapRef.current, {
      ...options,
      center: latLng,
    });

    mapsService.createMapMarker({
      map,
      position: latLng,
    });
  }, [latLng, mapsService, options]);

  return (
    <div
      css={style}
      data-testid={'map'}
      ref={mapRef}
    />
  );
}

GoogleMap.propTypes = {
  latLng: PropTypes.object.isRequired,
  mapsService: PropTypes.instanceOf(GoogleMapsService).isRequired,
  options: PropTypes.object,
  style: PropTypes.object,
};

function areEqual(prevProps, nextProps) {
  const previousLatLng = prevProps.latLng;
  const newLatLang = nextProps.latLng;

  return previousLatLng.lat === newLatLang.lat
    && previousLatLng.lng === newLatLang.lng;
}

export default React.memo(GoogleMap, areEqual);
