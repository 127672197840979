import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function reportAccountCreatedEventConfiguration({ rootId }) {
  return new NetworkRequestConfiguration({
    endpoint: 'attributions/touch_attributions/report_account_created_event',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      rootId,
    },
  });
}

