import LoginEmailLinkSent from '@root/renters.joinroot.com/src/login/scenes/login-email-link-sent';
import LoginWithEmail from '@root/renters.joinroot.com/src/login/scenes/login-with-email';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Routes from '@root/renters.joinroot.com/src/login/routes';
import { Route, Switch } from '@root/core/src/components/route';

export default function Router({ location, passProps }) {
  return (
    <Switch location={location}>
      <Route
        exact={true}
        path={Routes.EMAIL_LOGIN}
        render={() => (
          <LoginWithEmail
            {...passProps}
          />
        )}
      />
      <Route
        exact={true}
        path={Routes.EMAIL_SENT}
        render={() => (
          <LoginEmailLinkSent
            {...passProps}
          />
        )}
      />
    </Switch>
  );
}

Router.propTypes = {
  location: PropTypes.object.isRequired,
  passProps: PropTypes.object.isRequired,
};
