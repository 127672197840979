import AuthService from '@root/core/src/services/auth-service';
import ExpectedErrorResponseError from '@root/core/src/api/expected-error-response-error';
import Recase from '@root/vendor/recase';
import UnexpectedErrorResponseError from '@root/core/src/api/unexpected-error-response-error';
import environment from '@root/core/src/utils/environment';

const recase = Recase.create({});
const UUID_FORMAT = /[0-9a-f-]{36}/;

export default class ServerUtils {
  static async authenticatedGet(route, options = {}) {
    return ServerUtils._request(route, {
      headers: ServerUtils._getHeaders(true),
      method: 'GET',
      ...options,
    });
  }

  static async unauthenticatedGet(route, options = {}) {
    return ServerUtils._request(route, {
      headers: ServerUtils._getHeaders(false),
      method: 'GET',
      ...options,
    });
  }

  static async authenticatedPost(route, options = {}) {
    return ServerUtils._request(route, {
      headers: ServerUtils._getHeaders(true),
      method: 'POST',
      ...options,
    });
  }

  static async unauthenticatedPost(route, options = {}) {
    return ServerUtils._request(route, {
      headers: ServerUtils._getHeaders(false),
      method: 'POST',
      ...options,
    });
  }

  static async authenticatedPatch(route, options = {}) {
    return ServerUtils._request(route, {
      headers: ServerUtils._getHeaders(true),
      method: 'PATCH',
      ...options,
    });
  }

  static async unauthenticatedPatch(route, options = {}) {
    return ServerUtils._request(route, {
      headers: ServerUtils._getHeaders(false),
      method: 'PATCH',
      ...options,
    });
  }

  static async authenticatedPut(route, options = {}) {
    return ServerUtils._request(route, {
      headers: ServerUtils._getHeaders(true),
      method: 'PUT',
      ...options,
    });
  }

  static async unauthenticatedPut(route, options = {}) {
    return ServerUtils._request(route, {
      headers: ServerUtils._getHeaders(false),
      method: 'PUT',
      ...options,
    });
  }

  static async authenticatedDelete(route, options = {}) {
    return ServerUtils._request(route, {
      headers: ServerUtils._getHeaders(true),
      method: 'DELETE',
      ...options,
    });
  }

  static async _request(route, options = {}) {
    const query = ServerUtils._query(options);
    const url = ServerUtils._buildUrl(route, query);

    const fetchOptions = {
      method: options.method,
      headers: options.headers,
    };

    if (ServerUtils._isFormData(options)) {
      fetchOptions.body = options.body;
      fetchOptions.headers.delete('Content-Type');
    } else {
      fetchOptions.body = ServerUtils._body(options);
    }

    const expectedResponse = options.expectedResponse || 200;
    const expectedErrorResponses = options.expectedErrorResponses || [];

    const response = await fetch(url, fetchOptions);

    let json = {};

    if (response.status !== 204) {
      try {
        json = await response.json();
      } catch (e) {
        json = {};
      }
    }

    ServerUtils._checkResponseStatus(response, expectedResponse, expectedErrorResponses, json.message);

    return {
      rawResponse: response,
      data: recase.camelCopy(json),
    };
  }

  static _buildUrl(path, query = {}) {
    const { apiBaseUrl } = environment;
    const url = `${apiBaseUrl}${path}`;

    const searchParams = new URLSearchParams();
    const queryKeys = Object.keys(query);
    queryKeys.forEach((key) => searchParams.append(key, query[key]));

    return queryKeys.length > 0 ? `${url}?${searchParams.toString()}` : url;
  }

  static _body(options = {}) {
    if (options.body) {
      return JSON.stringify(recase.snakeCopy(options.body));
    }
  }

  static _query(options = {}) {
    if (options.query) {
      return recase.snakeCopy(options.query);
    }
  }

  static _checkResponseStatus(response, expected, expectedErrorResponses, errorMessage) {
    const expectedArray = Array.isArray(expected) ? expected : [expected];
    if (expectedArray.includes(response.status)) {
      return;
    }

    if (expectedErrorResponses.includes(response.status)) {
      throw new ExpectedErrorResponseError(errorMessage);
    }

    throw new UnexpectedErrorResponseError({
      message: `Unexpected ${response.status} on API call to ${ServerUtils._extractNormalizedUrlPath(response.url)}`,
      statusCode: response.status,
      url: response.url,
    });
  }

  static _extractNormalizedUrlPath(url) {
    if (!url) { return; }

    return '/' + url.split('/').slice(3).join('/').replace(UUID_FORMAT, '(uuid)');
  }

  static _getHeaders(withAuthentication) {
    const headers = new Headers({
      Accept: 'application/json',
      'Appetize-Simulation': false,
      'Client-Api-Version': environment.apiVersion,
      'Client-App-Name': 'Root Web',
      'Client-Device': 'Browser',
      'Client-Framework': 'React',
      'Content-Type': 'application/json',
    });

    if (withAuthentication) {
      headers.append('AUTH_TOKEN', AuthService.getAccessToken());
    }

    return headers;
  }

  static _isFormData(options = {}) {
    return options.body instanceof FormData;
  }
}
