import environment from '@root/core/src/utils/environment';

export async function loadGoogleMapsApiClient() {
  await new Promise((resolve) => {
    if (typeof window.google !== 'undefined') {
      resolve();
    } else {
      const { googleApiKey } = environment;
      window.__initMap = () => resolve();
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&callback=__initMap&libraries=places`;
      script.async = true;
      document.body.appendChild(script);
    }
  });
  return window.google;
}
