import Policy from '@root/renters.joinroot.com/src/models/policy';

export default class PolicyContext {
  constructor({
    showLandlordReminder,
    policy,
    landlord,
  } = {}) {
    this.showLandlordReminder = showLandlordReminder;
    this.policy = policy;
    this.landlord = landlord;
  }

  static buildFromData({
    showLandlordReminder,
    policy,
    landlord,
  }) {
    return Object.assign(
      new PolicyContext(),
      {
        showLandlordReminder,
        policy: Policy.buildFromData(policy),
        landlord,
      }
    );
  }
}
