import environment from '@root/core/src/utils/environment';

export default {
  CUSTOMER_SUPPORT_URL: `${environment.websiteBaseUrl}/contact/`,
  FCRA_DISCLAIMER_URL: `${environment.websiteBaseUrl}/fcra/`,
  FRAUD_DISCLAIMER_URL: `${environment.websiteBaseUrl}/fraud-disclaimer/`,
  PRIVACY_POLICY_URL: `${environment.websiteBaseUrl}/privacy-policy/`,
  TERMS_AND_CONDITIONS_URL: `${environment.websiteBaseUrl}/terms-and-conditions/`,
  MOBILE_APP_EULA_URL: `${environment.websiteBaseUrl}/app-eula/`,
  RECAPTCHA_PRIVACY_POLICY_URL: 'https://policies.google.com/privacy',
  RECAPTCHA_TERMS_OF_SERVICE_URL: 'https://policies.google.com/terms',
};
