import { RootError } from '@root-common/root-errors';

/**
 * The  UnexpectedErrorResponseError class is used to report unexpected error response errors to sentry.
 * @param {string} message The message body of the error.
 * @param {string=} team The team the error should be reported to.
 * @param {Object.<string,string>=} tags The tags to report to sentry.
 * @param {'fatal'|'error'|'info'=} severity The severity of the error, given by Sentry.Fatal, Sentry.Error, Sentry.Info
 * @param {string} url The endpoint the network request was contacting.
 * @param {number} statusCode The status code returned by the server.
 */

export default class UnexpectedErrorResponseError extends RootError {
  constructor({
    message, team, tags = {}, severity, url, statusCode,
  } = {}) {
    super({
      message,
      name: 'UnexpectedErrorResponseError',
      severity,
      tags,
      team,
      fingerprint: [message, url, statusCode],
    });
  }
}
