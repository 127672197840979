export default class GooglePlace {
  constructor(placeDetails) {
    this.components = this._normalizeComponents(placeDetails);
    this.location = placeDetails.geometry.location;
    this.formattedAddress = placeDetails.formatted_address;
  }

  get line1() {
    return [this._getComponent('street_number'), this._getComponent('route')].join(' ');
  }

  get city() {
    return this._getComponent('locality') ||
      this._getComponent('sublocality') ||
      this._getComponent('sublocality_level_one');
  }

  get state() {
    return this._getComponent('administrative_area_level_1');
  }

  get stateCode() {
    return this._getComponent('administrative_area_level_1', true);
  }

  get zip() {
    return this._getComponent('postal_code');
  }

  get country() {
    return this._getComponent('country', true);
  }

  get lat() {
    return this.location.lat();
  }

  get lng() {
    return this.location.lng();
  }

  _getComponent(key, short = false) {
    const component = this.components[key];
    return short ? component?.short_name : component?.long_name;
  }

  _normalizeComponents(rawDetails) {
    return rawDetails.address_components.reduce((acc, component) => {
      component.types.forEach((type) => {
        acc[type] = {
          short_name: component.short_name,
          long_name: component.long_name,
        };
      });
      return acc;
    }, {});
  }
}
