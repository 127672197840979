import AttributionService from '@root/attribution/src/services/attribution-service';
import EmailContext from '@root/renters.joinroot.com/src/contexts/email/email-context';
import EventReferrerContextProvider from '@root/renters.joinroot.com/src/contexts/event-referrer-context-provider';
import PolicyContextProvider from '@root/renters.joinroot.com/src/contexts/policy-context-provider';
import PropTypes from '@root/vendor/prop-types';
import ProtectedRouter from '@root/renters.joinroot.com/src/components/protected-router';
import QuotesContextProvider from '@root/renters.joinroot.com/src/contexts/quotes-context-provider';
import React, { useCallback, useEffect, useState } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import getEmailContextConfiguration from '@root/renters.joinroot.com/src/api/get-email-context-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';

const useCurrentEmailContext = () => {
  const [context, setContext] = useState();
  const [hasRequested, setHasRequested] = useState(false);
  const [getContext] = useImperativeNetworkRequest(getEmailContextConfiguration);

  const loadContext = useCallback(async () => {
    const result = await getContext();
    setContext(result.data.emailContext);
  }, [getContext]);

  useEffect(() => {
    if (!context && !hasRequested) {
      loadContext();
      setHasRequested(true);
    }
  }, [context, hasRequested, loadContext]);

  return {
    context,
    reloadContext: loadContext,
    isLoading: !context,
  };
};

export default function ProtectedApp({ passProps }) {
  useEffect(() => {
    AttributionService.associateVisitsToUser();
  }, []);

  const {
    context: emailContext,
    isLoading,
    reloadContext: reloadEmailContext,
  } = useCurrentEmailContext();

  if (isLoading) {
    return <SceneLoader />;
  }

  return (
    <EventReferrerContextProvider>
      <EmailContext.Provider
        value={{
          ...emailContext,
          reloadEmailContext,
        }}
      >
        <QuotesContextProvider>
          <PolicyContextProvider>
            <ProtectedRouter
              passProps={passProps}
            />
          </PolicyContextProvider>
        </QuotesContextProvider>
      </EmailContext.Provider>
    </EventReferrerContextProvider>
  );
}

ProtectedApp.propTypes = {
  passProps: PropTypes.object.isRequired,
};
