import Colors from '@root/brand/src/utils/colors';

export const opacityOverlayOnState = ({
  state = 'active',
  opacity = 0.3,
  baseStyles = {},
  stateStyles = {},
  borderWidth = 0,
  overlayColor = Colors.white(),
} = {}) => ({
  ':after': {
    content: '""',
    position: 'absolute',
    opacity: 0,
    background: overlayColor,
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    borderRadius: 'inherit',
    transition: 'opacity 200ms ease-in-out',
    ...borderWidth && {
      height: `calc(100% + ${borderWidth * 2}px)`,
      width: `calc(100% + ${borderWidth * 2}px)`,
      marginTop: borderWidth * -1,
      marginLeft: borderWidth * -1,
    },
    ...baseStyles,
  },
  [`:${state}`]: {
    ...stateStyles,
    ':after': {
      opacity,
    },
  },
});
