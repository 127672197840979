import Colors from '@root/brand/src/utils/colors';
import Gradients from '@root/brand/src/utils/gradients';
import Shadows from '@root/brand/src/utils/shadows';
import Theme from '@root/brand/src/utils/theme';
import styled from '@root/vendor/@emotion/styled';
import { Global, css as emotionCss } from '@root/vendor/@emotion/core';
import { StyleSheet as LegacyStyleSheet, css } from '@root/vendor/aphrodite/no-important';
import { css as createEmotionClassName } from '@root/vendor/emotion';

export class AnimationStyleSheet {
  static createClassNames(animationStyles) {
    const styles = StyleSheet.create(animationStyles);
    const classNames = {};

    Object.keys(styles).forEach((key) => classNames[key] = createEmotionClassName(styles[key].styles));

    classNames.appearActive = classNames.appearActive || '';
    classNames.enterActive = classNames.enterActive || '';
    classNames.exitActive = classNames.exitActive || '';
    classNames.appearActive += ' isTransitioning';
    classNames.enterActive += ' isTransitioning';
    classNames.exitActive += ' isTransitioning';

    return classNames;
  }
}

const emotionCreate = {
  create(styles) {
    return Object.keys(styles).reduce((obj, key) => {
      if (typeof styles[key] === 'object') {
        obj[key] = emotionCss({
          label: key,
          ...styles[key],
        });
      } else {
        obj[key] = emotionCss(`label: ${ key };` + styles[key]);
      }

      return obj;
    }, {});
  },
};

const StyleSheet = emotionCreate;

export { Shadows, LegacyStyleSheet, StyleSheet, createEmotionClassName, css, Colors, Gradients, Theme, styled, Global };
