import IneligibleLanding from '@root/renters.joinroot.com/src/ineligible/scenes/ineligible-landing';
import Landing from '@root/renters.joinroot.com/src/landing/scenes/landing';
import PropTypes from '@root/vendor/prop-types';
import React, { lazy } from '@root/vendor/react';
import Routes from '@root/renters.joinroot.com/src/routes/public-routes.js';
import { Redirect, Route, Switch } from '@root/core/src/components/route';

const LoginEntry = lazy(() => import(/* webpackChunkName: "login-entry" */'@root/renters.joinroot.com/src/login/components/entry'));

export default function PublicRouter({ passProps }) {
  return (
    <Switch>
      <Route
        exact={true}
        path={Routes.LANDING}
        render={() => (
          <Landing
            {...passProps}
          />
        )}
      />
      <Route
        path={Routes.LOGIN}
        render={() => <LoginEntry passProps={passProps} />}
      />
      <Route
        path={Routes.INELIGIBLE}
        render={() => <IneligibleLanding {...passProps} />}
      />
      <Redirect to={Routes.LANDING} />
    </Switch>
  );
}

PublicRouter.propTypes = {
  passProps: PropTypes.object.isRequired,
};
