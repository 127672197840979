import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import ZIndex from '@root/core/src/utils/z-index';
import { AnimationStyleSheet, Colors, StyleSheet } from '@root/core/src/utils/styles';
import { CSSTransition, TransitionGroup } from '@root/vendor/react-transition-group';

export default function SliderAnimator({ children }) {
  return (
    <TransitionGroup>
      {children && (
        <CSSTransition
          classNames={backgroundAnimationClassNames}
          in={!!children}
          key={`${children}-background`}
          timeout={300}
        >
          <div css={styles.background} />
        </CSSTransition>
      )}
      {children && (
        <CSSTransition
          classNames={animationClassNames}
          in={!!children}
          key={`${children}-card`}
          timeout={300}
        >
          {children}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

const styles = StyleSheet.create({
  background: {
    height: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    background: Colors.DEPRECATED_overlayShadow(),
    zIndex: ZIndex.MODAL_OVERLAY_BACKGROUND,
  },
});

const backgroundAnimationClassNames = AnimationStyleSheet.createClassNames({
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
    transition: 'all 300ms ease-out',
  },
  enterDone: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
  exitActive: {
    opacity: 0,
    transition: 'all 300ms ease-out',
  },
  exitDone: {
    opacity: 0,
  },
});

const animationClassNames = AnimationStyleSheet.createClassNames({
  enter: {
    transform: 'translateY(100%)',
    ...Responsive.sm({
      transform: 'none',
      opacity: 0,
    }),
  },
  enterActive: {
    transform: 'translateY(0%)',
    ...Responsive.sm({
      transform: 'none',
      opacity: 1,
    }),
    transition: 'all 300ms ease-out',
  },
  enterDone: {
    transform: 'translateY(0%)',
    ...Responsive.sm({
      transform: 'none',
      opacity: 1,
    }),
  },
  exit: {
    transform: 'translateY(0%)',
    ...Responsive.sm({
      transform: 'none',
      opacity: 1,
    }),
  },
  exitActive: {
    transform: 'translateY(100%)',
    ...Responsive.sm({
      transform: 'none',
      opacity: 0,
    }),
    transition: 'all 300ms ease-out',
  },
  exitDone: {
    transform: 'translateY(100%)',
    ...Responsive.sm({
      transform: 'none',
      opacity: 0,
    }),
  },
});

SliderAnimator.propTypes = {
  children: PropTypes.node,
};
