import Coverage from '@root/renters.joinroot.com/src/models/coverage';

export default class Quote {
  static BASE = 'base';
  static ESSENTIAL = 'essential';
  static PREMIER = 'premier';

  constructor({
    id,
    tier,
    coverages,
    totalPremiumInCents,
    feePerPolicyPaymentInCents,
    monthlyTotalPremiumInCents,
    fullTermTotalPremiumInCents,
    monthlyTaxInCents,
    fullTermTaxInCents,
  } = {}) {
    this.id = id;
    this.tier = tier;
    this.coverages = coverages;
    this.totalPremiumInCents = totalPremiumInCents;
    this.feePerPolicyPaymentInCents = feePerPolicyPaymentInCents;
    this.monthlyTotalPremiumInCents = monthlyTotalPremiumInCents;
    this.fullTermTotalPremiumInCents = fullTermTotalPremiumInCents;
    this.monthlyTaxInCents = monthlyTaxInCents;
    this.fullTermTaxInCents = fullTermTaxInCents;
  }

  static buildFromData({
    id, tier, coverages, totalPremiumInCents, feePerPolicyPaymentInCents, monthlyTotalPremiumInCents, fullTermTotalPremiumInCents, monthlyTaxInCents, fullTermTaxInCents,
  } = {}) {
    return Object.assign(
      new Quote(),
      {
        id,
        tier,
        coverages: coverages.map((c) => Coverage.buildFromData(c)),
        totalPremiumInCents,
        feePerPolicyPaymentInCents,
        monthlyTotalPremiumInCents,
        fullTermTotalPremiumInCents,
        monthlyTaxInCents,
        fullTermTaxInCents,
      },
    );
  }

  static tierName(tier) {
    switch (tier) {
    case Quote.BASE:
      return 'Base';
    case Quote.ESSENTIAL:
      return 'Essential';
    case Quote.PREMIER:
      return 'Premier';
    }
  }

  getPremiumInCentsByBillingCycle(billingCycle) {
    if (billingCycle === 'full_term') {
      return this.fullTermTotalPremiumInCents + this.fullTermTaxInCents;
    }
    return this.monthlyTotalPremiumInCents + (this.feePerPolicyPaymentInCents || 0) + this.monthlyTaxInCents;
  }
}
