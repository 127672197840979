import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getNewBusinessQuotingEligibilityConfiguration(eligibilityParams) {
  return new NetworkRequestConfiguration({
    endpoint: 'renters/new_business_quoting_eligibility',
    method: NetworkRequestConfiguration.Methods.GET,
    queryParameters: {
      ...eligibilityParams,
    },
  });
}
