export const BUTTON_SIZE_REGULAR = 'regular';
export const BUTTON_SIZE_SMALL = 'small';

export const BUTTON_SIZES = [
  BUTTON_SIZE_REGULAR,
  BUTTON_SIZE_SMALL,
];

export const BUTTON_SIZE_STYLES = {
  [BUTTON_SIZE_REGULAR]: {
    minHeight: 60,
  },
  [BUTTON_SIZE_SMALL]: {
    minHeight: 44,
  },
};
