import * as Sentry from '@root/vendor/@sentry/react';
import FunnelStage from '@root/core/src/utils/funnel-stage';
import PropTypes from '@root/vendor/prop-types';
import environment from '@root/core/src/utils/environment';
import { Redirect as ReactRouterRedirect, Route as ReactRouterRoute } from '@root/vendor/react-router-dom';

export function FunnelRoute(RegularRoute) {
  const componentDisplayName = RegularRoute.displayName || RegularRoute.name;

  const WrappedRoute = (props) => {
    if (props.component) {
      return (
        <RegularRoute
          {...props}
          component={(compProps) => {
            FunnelStage.setFunnelStage(compProps.funnelStage);
            return props.component(compProps);
          }}
        />
      );
    }
    FunnelStage.setFunnelStage(props.funnelStage);
    return (
      <RegularRoute
        {...props}
        render={(renderProps) => props.render(renderProps)}
      />
    );
  };

  WrappedRoute.propTypes = {
    component: PropTypes.any,
    funnelStage: PropTypes.string,
    render: PropTypes.any,
  };

  WrappedRoute.displayName = `funnelStageRoute(${componentDisplayName})`;
  return WrappedRoute;
}

FunnelRoute.propTypes = {
  component: PropTypes.any,
  funnelStage: PropTypes.string,
  render: PropTypes.any,
};

export const Route = environment.isFunnelLoggingEnabled ? Sentry.withSentryRouting(FunnelRoute(ReactRouterRoute)) : Sentry.withSentryRouting(ReactRouterRoute);

export function Redirect({
  to, shouldPreserveQueryParams = true, ...props
}) {
  if (!shouldPreserveQueryParams) {
    return (
      <ReactRouterRedirect
        to={to}
        {...props}
      />
    );
  }

  return (
    <Route
      component={({ location }) => (
        <ReactRouterRedirect
          to={{
            search: location.search,
            pathname: to,
          }}
        />
      )}
    />
  );
}

Redirect.propTypes = {
  shouldPreserveQueryParams: PropTypes.bool,
  to: PropTypes.any,
};

export { Switch, withRouter, useHistory, useLocation, useRouteMatch } from '@root/vendor/react-router-dom';
