import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export const BUTTON_ICON_POSITION_LEFT = 'left';
export const BUTTON_ICON_POSITION_RIGHT = 'right';

export const BUTTON_ICON_STYLESHEET = StyleSheet.create({
  icon: {
    fill: Colors.white(),
    width: 24,
    height: 24,
  },
  [BUTTON_ICON_POSITION_LEFT]: {
    marginRight: 10,
  },
  [BUTTON_ICON_POSITION_RIGHT]: {
    marginLeft: 10,
  },
});

export const BUTTON_ICON_POSITIONS = [
  BUTTON_ICON_POSITION_LEFT,
  BUTTON_ICON_POSITION_RIGHT,
];

export const BUTTON_ICON_POSITION_STYLES = {
  [BUTTON_ICON_POSITION_RIGHT]: {
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
};
