import PrimaryNamedInsured from '@root/renters.joinroot.com/src/models/primary-named-insured';
import RiskAddress from '@root/renters.joinroot.com/src/models/risk-address';
import dayjs from '@root/vendor/dayjs';

export default class Policy {
  constructor({
    id,
    number,
    isActive,
    canCancel,
    hasEffectiveCancellation,
    invoicePeriod,
    termEffectiveDatetime,
    termExpirationDatetime,
    riskAddress,
    primaryNamedInsured,
  } = {}) {
    this.id = id;
    this.number = number;
    this.isActive = isActive;
    this.canCancel = canCancel;
    this.hasEffectiveCancellation = hasEffectiveCancellation;
    this.invoicePeriod = invoicePeriod;
    this.termEffectiveDatetime = termEffectiveDatetime;
    this.termExpirationDatetime = termExpirationDatetime;
    this.riskAddress = riskAddress;
    this.primaryNamedInsured = primaryNamedInsured;
  }

  static buildFromData({
    id,
    number,
    isActive,
    canCancel,
    hasEffectiveCancellation,
    invoicePeriod,
    termEffectiveDatetime,
    termExpirationDatetime,
    riskAddress,
    primaryNamedInsured,
  }) {
    return Object.assign(
      new Policy(),
      {
        id,
        number,
        isActive,
        canCancel,
        hasEffectiveCancellation,
        invoicePeriod,
        termEffectiveDatetime,
        termExpirationDatetime,
        riskAddress: RiskAddress.buildFromData(riskAddress),
        primaryNamedInsured: PrimaryNamedInsured.buildFromData(primaryNamedInsured),
      },
    );
  }

  get effectiveDateRange() {
    const dateFormat = 'M/DD/YYYY';
    const startDate = dayjs(this.termEffectiveDatetime).format(dateFormat);
    const endDate = dayjs(this.termExpirationDatetime).format(dateFormat);
    return `${startDate} - ${endDate}`;
  }
}
