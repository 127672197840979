import EventReferrerStorageService from '@root/renters.joinroot.com/src/services/event-referrer-storage-service';
import EventReferrers from '@root/shared-quotes/src/models/event-referrers';
import PropTypes from '@root/vendor/prop-types';
import React, { useContext } from '@root/vendor/react';
import { useHistory } from '@root/core/src/components/route';

export const EventReferrerContext = React.createContext();

const getEventReferrer = ({ search }) => {
  const eventReferrerParams = new URLSearchParams(search);
  if (eventReferrerParams.has(EventReferrers.PARAMETER_NAME)) {
    EventReferrerStorageService.storeEventReferrer(eventReferrerParams.get(EventReferrers.PARAMETER_NAME));
  }
  return EventReferrerStorageService.retrieveEventReferrer();
};

const EventReferrerContextProvider = ({ children }) => {
  const history = useHistory();
  return (
    <EventReferrerContext.Provider
      value={{
        eventReferrer: getEventReferrer(history.location),
      }}
    >
      {children}
    </EventReferrerContext.Provider>
  );
};

EventReferrerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EventReferrerContextProvider;

export const useEventReferrerContext = () => {
  return useContext(EventReferrerContext);
};
