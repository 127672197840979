import StorageService from '@root/core/src/services/storage-service';

export const EVENT_REFERRER_STORAGE_KEY_V1 = 'renters/eventReferrerV1';
class EventReferrerStorageService {
  static storeEventReferrer(eventReferrer) {
    StorageService.setItem(EVENT_REFERRER_STORAGE_KEY_V1, eventReferrer);
  }

  static retrieveEventReferrer = () => {
    return StorageService.getItem(EVENT_REFERRER_STORAGE_KEY_V1);
  };
}

export default EventReferrerStorageService;
