import AnalyticsContext from '@root/core/src/contexts/analytics/analytics-context';
import AnalyticsService from '@root/core/src/services/analytics-service';
import { useCallback, useContext, useEffect } from '@root/vendor/react';

export default function useAnalytics(analyticsContext, shouldTrackViewEvent = true) {
  const { eventPrefix } = useContext(AnalyticsContext);

  useEffect(() => {
    if (shouldTrackViewEvent) {
      const eventProperties = {
        eventPrefix,
      };

      AnalyticsService.trackViewEvent(analyticsContext, eventProperties);
    }
  }, [analyticsContext, eventPrefix, shouldTrackViewEvent]);

  return {
    trackClick: useCallback((event, properties) => {
      const eventProperties = {
        eventPrefix,
        ...properties,
      };
      AnalyticsService.trackClickEvent(analyticsContext, event, eventProperties);
    }, [analyticsContext, eventPrefix]),
    trackEvent: useCallback((event, properties) => {
      const eventProperties = {
        eventPrefix,
        ...properties,
      };

      AnalyticsService.trackEvent(analyticsContext, event, eventProperties);
    }, [analyticsContext, eventPrefix]),
  };
}
