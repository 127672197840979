import Button from '@root/core/src/components/button';
import Modal from '@root/core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { BUTTON_VARIANT_SECONDARY } from '@root/core/src/components/button/styles';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export const EmailConfirmationModal = ({
  email,
  isShowing = true,
  onCancel,
  onConfirmEmailPress,
  onEditEmailPress,
  showDismissX,
}) => {
  return (
    <Modal
      isShowing={isShowing}
      name={'email-confirmation'}
      onCancel={onCancel}
      showCloseButton={showDismissX}
    >
      <div css={styles.title}>
        Did you spell everything right?
      </div>
      {'You entered '}<span css={styles.bold}>{email}</span>
      <div css={styles.buttonContainer}>
        <Button onClick={onEditEmailPress}>
          Edit Email
        </Button>
        <Button
          onClick={onConfirmEmailPress}
          variant={BUTTON_VARIANT_SECONDARY}
        >
          Continue
        </Button>
      </div>
    </Modal>

  );
};

EmailConfirmationModal.propTypes = {
  email: PropTypes.string.isRequired,
  isShowing: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirmEmailPress: PropTypes.func.isRequired,
  onEditEmailPress: PropTypes.func.isRequired,
  showDismissX: PropTypes.bool,
};

const styles = StyleSheet.create({
  bold: semiBold(),
  buttonContainer: {
    paddingTop: 24,
  },
  title: {
    ...Theme.cardHeading(),
    fontSize: 32,
    paddingBottom: 16,
  },
});
