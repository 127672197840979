import ErrorReportService from '@root/core/src/services/error-report-service';
import useNetworkRequestBase from '@root/core/src/hooks/use-network-request-base';
import { useCallback } from '@root/vendor/react';

export default function useSafeNetworkRequest(networkRequestConfiguration, resultHandlerCallback) {
  return useNetworkRequestBase(networkRequestConfiguration, useCallback((result) => {
    if (result.isUnexpectedError()) {
      ErrorReportService.reportError({
        error: result.error,
        caughtAt: 'network-error',
        additionalData: result.error.additionalData,
      });
    }

    resultHandlerCallback(result);
  }, [resultHandlerCallback]));
}
