import debounce from '@root/vendor/lodash/debounce';

export default class GoogleMapsService {
  constructor(googleApiClient) {
    this.mapsClient = googleApiClient.maps;
    this.placesClient = this.mapsClient.places;
    this.geocoder = new this.mapsClient.Geocoder();
    this.autocompleteService = new this.placesClient.AutocompleteService();
  }

  createMap(node, mapOptions = {}) {
    return new this.mapsClient.Map(node, mapOptions);
  }

  createMapMarker({
    map, position, ...otherOptions
  } = {}) {
    return new this.mapsClient.Marker({
      map,
      position,
      ...otherOptions,
    });
  }

  getPlacePredictions = debounce((input, callback) => {
    if (input?.length > 3) {
      this.autocompleteService.getPlacePredictions({
        input,
        componentRestrictions: {
          country: 'us',
        },
        types: ['address'],
      }, (predictions, status) => {
        if (this._isPlacesStatusOK(status)) {
          callback(predictions);
        } else {
          callback([]);
        }
      });
    }
  }, 600)

  getPlaceDetails(placeId, callback, error, node = null) {
    const requiredNode = node || document.createElement('div');
    const placesService = new this.placesClient.PlacesService(requiredNode);
    placesService.getDetails({
      placeId,
      fields: ['address_component', 'geometry', 'formatted_address'],
    }, (details, status) => {
      if (this._isPlacesStatusOK(status)) {
        callback(details);
      } else {
        error();
      }
    });
  }

  _isPlacesStatusOK(status) {
    return status === this.placesClient.PlacesServiceStatus.OK;
  }
}
