import React from '@root/vendor/react';
import { RootIdService } from '@root/attribution/src/services/root-id-service';

function useRootId() {
  return RootIdService.read();
}

function withRootId(WrappedComponent) {
  const WithRootId = (props) => {
    return (
      <WrappedComponent
        rootId={useRootId()}
        {...props}
      />
    );
  };

  return WithRootId;
}

export { useRootId, withRootId };
