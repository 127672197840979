import Colors from '@root/brand/src/utils/colors';

export default class Shadows {
  static CARD_BOX_SHADOW = '0px 8px 23px rgba(0,0,0,0.1)';
  static MODAL_BOX_SHADOW = '0px 8px 23px rgba(0,0,0,0.1)';
  static HOVER_BOX_SHADOW = '0px 4px 6px rgba(0,0,0,0.2)';
  static FOCUS_STATE_SHADOW = `0 0 0 6px ${Colors.rootOrange()}33`; // 33 is for 20% transparency

  static normal() {
    return {
      boxShadow: `0px 18px 10px -8px ${Colors.DEPRECATED_blackShadow()}`,
      transition: 'all 300ms ease-out',
    };
  }

  static sidesAndBottom() {
    return {
      boxShadow: `0px 16px 30px ${Colors.DEPRECATED_blackShadow()}`,
    };
  }

  static top() {
    return {
      boxShadow: `0px -18px 15px 0px ${Colors.DEPRECATED_shadow(0.15)}`,
    };
  }

  static none() {
    return {
      boxShadow: 'none',
    };
  }

  static modal() {
    return {
      boxShadow: this.MODAL_BOX_SHADOW,
    };
  }

  static focusShadow(shadowColor = undefined) {
    return {
      boxShadow: shadowColor ? this.FOCUS_STATE_SHADOW.replace(Colors.rootOrange(), shadowColor) : this.FOCUS_STATE_SHADOW,
    };
  }

  static onFocusStateShadow(focusStyles = {}, shadowColor = undefined) {
    return {
      transition: 'all 200ms ease-in-out',
      ':focus': {
        outline: 'none',
        ':not(:disabled)': {
          ...focusStyles,
          ...this.focusShadow(shadowColor),
        },
      },
    };
  }

  static cardShadow() {
    return {
      boxShadow: this.CARD_BOX_SHADOW,
    };
  }

  static hoverShadow() {
    return {
      boxShadow: this.HOVER_BOX_SHADOW,
    };
  }

  static onHoverStateShadow(hoverStyles = {}) {
    return {
      transition: 'all 200ms ease-in-out',
      ':hover': {
        ...hoverStyles,
        ...this.hoverShadow(),
      },
    };
  }

  static softShadow() {
    return {
      boxShadow: `
        0px 16px 16px ${Colors.DEPRECATED_shadow(0.05)},
        0px 8px 8px ${Colors.DEPRECATED_shadow(0.03)}
      `,
    };
  }

  static accountCardShadow() {
    return {
      boxShadow: `
        0px 13px 13px ${Colors.DEPRECATED_shadow(0.05)},
        0px 6px 6px ${Colors.DEPRECATED_shadow(0.03)}
      `,
    };
  }

  static modalDark() {
    return {
      boxShadow: `0px 20px 30px ${Colors.DEPRECATED_shadow(0.77)}`,
    };
  }

  static sliderHandle() {
    return {
      boxShadow: `0px 6px 12px 1px ${Colors.DEPRECATED_blackShadow()}`,
    };
  }

  static socialButton() {
    return {
      boxShadow: `0px 8px 22px ${Colors.DEPRECATED_shadow(0.2)}, 0px 10px 10px ${Colors.DEPRECATED_blackShadow()}`,
    };
  }

  static modalButton() {
    return {
      boxShadow: `0px 22px 22px ${Colors.DEPRECATED_shadow(0.2)}, 0px 19px 10px ${Colors.DEPRECATED_shadow(0.3)}`,
    };
  }
}
