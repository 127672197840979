import Quote from '@root/renters.joinroot.com/src/models/quote';
import RiskAddress from '@root/renters.joinroot.com/src/models/risk-address';

export default class QuotesContext {
  constructor({
    hasRequested, quotes, riskAddress,
  } = {}) {
    this.quotes = quotes;
    this.riskAddress = riskAddress;
    this.hasRequestedQuotes = hasRequested;
  }

  static buildFromData({
    hasRequested, quotes, riskAddress,
  } = {}) {
    return Object.assign(
      new QuotesContext(),
      {
        quotes: quotes.map((q) => Quote.buildFromData(q)),
        riskAddress: RiskAddress.buildFromData(riskAddress),
        hasRequestedQuotes: hasRequested,
      },
    );
  }
}
