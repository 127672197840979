import PropTypes from '@root/vendor/prop-types';
import QuotesContextModel from '@root/renters.joinroot.com/src/models/quotes-context';
import React, { useContext, useEffect, useReducer } from '@root/vendor/react';
import getQuotesContextConfiguration from '@root/renters.joinroot.com/src/api/request-quotes-context-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';

export const QuotesContext = React.createContext();

const actionTypes = {
  DEPRECATE_QUOTES_CONTEXT: 'DEPRECATE_QUOTES_CONTEXT',
  SET_QUOTES_CONTEXT: 'SET_QUOTES_CONTEXT',
};

function reducer(state, action) {
  switch (action.type) {
  case actionTypes.DEPRECATE_QUOTES_CONTEXT: {
    return {
      isFetched: false,
    };
  }
  case actionTypes.SET_QUOTES_CONTEXT: {
    return {
      context: action.context,
      isFetched: true,
    };
  }
  }
}

export default function QuotesContextProvider({ children, initialContext }) {
  const initialState = initialContext
    ? {
      context: initialContext,
      isFetched: true,
    }
    : {};

  const [state, dispatch] = useReducer(reducer, initialState);

  function dispatchSetQuotesContext(context) {
    dispatch({
      context,
      type: actionTypes.SET_QUOTES_CONTEXT,
    });
  }

  function dispatchDeprecateQuotesContext() {
    dispatch({
      type: actionTypes.DEPRECATE_QUOTES_CONTEXT,
    });
  }
  return (
    <QuotesContext.Provider
      value={{
        context: state.context,
        fetched: state.isFetched,
        dispatchDeprecateQuotesContext,
        dispatchSetQuotesContext,
      }}
    >
      {children}
    </QuotesContext.Provider>
  );
}

QuotesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialContext: PropTypes.instanceOf(QuotesContextModel),
};

export function useQuotesContext() {
  const [getContext, isRequesting] = useImperativeNetworkRequest(getQuotesContextConfiguration);
  const {
    context,
    dispatchSetQuotesContext,
    fetched,
  } = useContext(QuotesContext);

  useEffect(() => {
    if (!isRequesting && !fetched) {
      getContext().then(({ data: { quotesContext } }) => {
        dispatchSetQuotesContext(quotesContext
          ? QuotesContextModel.buildFromData(quotesContext)
          : null
        );
      });
    }
  }, [context, dispatchSetQuotesContext, fetched, getContext, isRequesting]);

  return {
    context,
    fetched,
  };
}
