export default class PrimaryNamedInsured {
  constructor({
    dob,
    firstName,
    lastName,
  } = {}) {
    this.dob = dob;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  static buildFromData({
    dob,
    firstName,
    lastName,
  } = {}) {
    return Object.assign(
      new PrimaryNamedInsured(),
      {
        dob,
        firstName,
        lastName,
      },
    );
  }

  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }
}
