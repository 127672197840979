const dayjs = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);

module.exports = dayjs;
