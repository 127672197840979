import AnalyticsService from '@root/core/src/services/analytics-service';
import AttributionQueryParamService from '@root/attribution/src/services/attribution-query-param-service';
import CookieStorageService from '@root/core/src/services/cookie-storage-service';
import NetworkService from '@root/core/src/services/network-service';
import uuid from '@root/vendor/uuid/v4';
import { associateVisitsToUser, trackVisit } from '@root/attribution/src/api/root-server';
import { createWebAttribution } from '@root/attribution/src/api/root-server';

export const AttributionParameterKeys = [
  'pid',
  'c',
  'af_c_id',
  'af_adset',
  'af_adset_id',
  'af_ad',
  'af_ad_id',
];

export const AttributionMediaSourceKey = 'pid';

export const AttributionActions = {
  ACCOUNT: 'account',
  PREFILL: 'prefill',
  PROFILE: 'profile',
  QUOTE: 'quote',
  BIND: 'bind',
};

export default class AttributionService {
  static VISIT_COOKIE_KEY = 'first-visit-tracking';

  static VISIT_SESSION_COOKIE_KEY = 'visit-tracking-for-session'
  static VISIT_EVENT_SESSION_COOKIE_KEY = 'visit-event-tracking-for-session'

  static formattedMediaSourceParameter(attributionQueryParameters) {
    return attributionQueryParameters?.[AttributionMediaSourceKey]?.toLowerCase();
  }

  static storeAttributionReferralHref(sourcePathUrl) {
    AttributionQueryParamService.store(sourcePathUrl);
  }

  static getPartnerProvidedParams = () => {
    const now = new Date();
    const attribution = AttributionQueryParamService.read();
    const formattedMediaSource = this.formattedMediaSourceParameter(attribution);
    if (attribution?.expires > now.getTime()
        && formattedMediaSource
        && attribution.pp_fn
        && attribution.pp_ln
        && attribution.pp_ln.length > 1) {
      let maritalStatus = null;
      if (attribution.pp_ms) {
        maritalStatus = attribution.pp_ms[0].toUpperCase() + attribution.pp_ms.slice(1).trim().toLowerCase();
      }
      return {
        partnerProvided: {
          partner: formattedMediaSource,
          firstName: attribution.pp_fn,
          lastName: attribution.pp_ln,
          maritalStatus,
          mailingAddress: {
            line1: attribution.pp_addr1 || null,
            line2: attribution.pp_addr2 || null,
            city: attribution.pp_cty || null,
            state: attribution.pp_st || null,
            zip: attribution.pp_z || null,
            latitude: null,
            longitude: null,
          },
          vin: attribution.pp_vin || null,
        },
      };
    }

    if (attribution?.expires > now.getTime()
    && formattedMediaSource
  && attribution.pp_vin?.length) {
      return {
        partnerProvided: {
          vin: attribution.pp_vin || null,
        },
      };
    }

    return {};
  };

  static getPartnerDriverIdentifiers = () => {
    const now = new Date();
    const attribution = AttributionQueryParamService.read();

    if (attribution?.expires > now.getTime()) {
      return {
        rootEnterpriseClientId: attribution.pp_reci || null,
        rootEnterpriseDriverId: attribution.pp_redi || null,
      };
    }
    return {};
  }

  static trackAttributionAction(attributionAction) {
    const now = new Date();
    const attribution = AttributionQueryParamService.read();
    let sourcePathUrl;
    let touchTimestamp;

    if (attribution?.expires > now.getTime()) {
      sourcePathUrl = attribution.sourcePathUrl;
      touchTimestamp = attribution.touchTimestamp;
    } else {
      sourcePathUrl = window.document.location.href;
      touchTimestamp = now.toUTCString();
    }

    return NetworkService.safeRequest(createWebAttribution, {
      sourcePathUrl,
      referrerUrl: document.referrer,
      attributionAction,
      touchTimestamp,
      mixpanelDistinctId: AnalyticsService.getDistinctId(),
    });
  }

  static async trackVisit(touchTimestamp = new Date().toUTCString()) {
    if (!CookieStorageService.has(this.VISIT_SESSION_COOKIE_KEY)) {
      const visitTrackingid = this._getOrSetTrackingId();

      CookieStorageService.set(this.VISIT_SESSION_COOKIE_KEY, new Date().getTime(), {
        expires: null,
      });

      await NetworkService.safeRequest(trackVisit, {
        sourcePathUrl: window.document.location.href,
        referrerUrl: document.referrer,
        touchTimestamp,
        mixpanelDistinctId: AnalyticsService.getDistinctId(),
        visitTrackingid,
      });
    }

    window.whiteOpsPixel && window.whiteOpsPixel();
  }

  static async trackVisitEvent({ rootId, reportVisitEvent }) {
    if (!CookieStorageService.has(this.VISIT_EVENT_SESSION_COOKIE_KEY)) {
      CookieStorageService.set(this.VISIT_EVENT_SESSION_COOKIE_KEY, new Date().getTime(), {
        expires: null,
      });

      reportVisitEvent({
        rootId,
      });
    }
  }

  static async associateVisitsToUser() {
    await this.trackVisit();
    if (CookieStorageService.has(this.VISIT_COOKIE_KEY)) {
      await NetworkService.safeRequest(associateVisitsToUser, {
        visitTrackingid: CookieStorageService.get(this.VISIT_COOKIE_KEY).visitTrackingid,
      });
    }
  }

  static _getOrSetTrackingId() {
    if (!CookieStorageService.has(this.VISIT_COOKIE_KEY)) {
      const visitTrackingid = uuid();
      this._setVisitCookie({
        visitTrackingid,
      });

      return visitTrackingid;
    }

    return CookieStorageService.get(this.VISIT_COOKIE_KEY).visitTrackingid;
  }

  static _setVisitCookie({ visitTrackingid }) {
    CookieStorageService.set(this.VISIT_COOKIE_KEY, {
      visitTrackingid,
    },
    {
      expires: 365 * 10,
    });
  }
}
