import ErrorReportService from '@root/core/src/services/error-report-service';
import ExpectedErrorResponseError from '@root/core/src/api/expected-error-response-error';
import NetworkConnectivityError from '@root/core/src/networking/network-connectivity-error';
import UnexpectedErrorResponseError from '@root/core/src/api/unexpected-error-response-error';
import { RootError } from '@root-common/root-errors';

export default class NetworkService {
  static handleNetworkError(error, params, stack, isSafe) {
    if (error instanceof ExpectedErrorResponseError) { return; }
    const errorToReport = error instanceof RootError ? error : new RootError({
      name: 'NetworkServiceError',
      message: error.message || error,
      fingerprint: ['NetworkServiceError'],
    });
    errorToReport.stack = stack;
    errorToReport.additionalData = {
      fetchBody: params,
    };

    if (isSafe) {
      if (error instanceof NetworkConnectivityError) { return; }
      ErrorReportService.reportError({
        error: errorToReport,
        caughtAt: 'network-error',
        additionalData: {
          fetchBody: params,
        },
      });
    } else {
      throw error;
    }
  }
}

export class NetworkResult {
  constructor(data, error) {
    this._data = data;
    this._error = error;
  }

  get data() {
    return this._data;
  }

  get error() {
    return this._error;
  }

  get isSuccess() {
    return !this.error;
  }

  get isError() {
    return !this.isSuccess;
  }
}

class NetworkRequest {
  constructor(request, params, isSafe) {
    this._request = request;
    this._params = params;
    // eslint-disable-next-line root/deprecate-constructor
    this._stack = new Error('error').stack;
    this._isSafe = isSafe;
  }

  async request() {
    let data, error;
    try {
      data = await this._request(this._params);
    } catch (err) {
      error = err || new RootError({
        message: 'request promise was rejected without throwing',
        name: 'NetworkRequestError',
      });
      if (!(error instanceof ExpectedErrorResponseError) && !(error instanceof UnexpectedErrorResponseError)) {
        error = new NetworkConnectivityError(error.message || error);
      }

      NetworkService.handleNetworkError(error, this._params, this._stack, this._isSafe);
    }

    return new NetworkResult(data, error);
  }
}

NetworkService.request = function (request, params) {
  return new NetworkRequest(request, params, false).request();
};

NetworkService.safeRequest = function (request, params) {
  return new NetworkRequest(request, params, true).request();
};
