import GoogleMapsService from '@root/core/src/services/google-maps-service';
import Input from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React, { forwardRef, useImperativeHandle } from '@root/vendor/react';
import Theme from '@root/brand/src/utils/theme';
import ZIndex from '@root/core/src/utils/z-index';
import search from '@root/core/src/assets/search.svg';
import useAutocompleteAddressInput from '@root/core/src/hooks/use-autocomplete-address-input';
import { Colors, Shadows, StyleSheet } from '@root/core/src/utils/styles';

function AutocompleteAddressInput({
  errorLabel,
  focusOnMount = false,
  googleService,
  inputStyle,
  inputWrapperStyle,
  label = 'Address, city, state, ZIP',
  maxPredictionsShown = 5,
  onChange,
  onFocus,
  onPredictionSelect = () => {},
  onPredictionNotFoundClick,
}, ref) {
  const {
    createClickHandler,
    createEnterKeyPressHandler,
    fieldProps,
    predictions,
    selectedGooglePlace,
  } = useAutocompleteAddressInput(googleService, maxPredictionsShown, onPredictionNotFoundClick);

  useImperativeHandle(ref, () => ({
    selectedGooglePlace,
  }),);

  const handlePredictionClick = createClickHandler((googlePlace) => {
    onPredictionSelect(googlePlace);
  });

  const handleEnterKeyPress = createEnterKeyPressHandler((googlePlace) => {
    onPredictionSelect(googlePlace);
  });

  const addressInputStyle = predictions.length > 0
    ? [styles.addressInputOpen, styles.addressInputOverflow]
    : styles.addressInputOverflow;

  const searchImage = (
    <img
      css={styles.searchImage}
      src={search}
    />
  );

  return (
    <>
      <Input
        autoComplete={'off'}
        errorLabel={errorLabel}
        focusOnMount={focusOnMount}
        inputStyle={[addressInputStyle, inputStyle]}
        label={label}
        onKeyPress={handleEnterKeyPress}
        rightIconComponent={searchImage}
        wrapperStyle={inputWrapperStyle}
        {...fieldProps({
          onChange,
          onFocus,
        })}
        ref={ref}
      />
      <div css={styles.listContainer}>
        <ul css={styles.list}>
          {predictions.slice(0, maxPredictionsShown).map((prediction, index) => {
            const style = prediction.isFocused
              ? [styles.li, styles.focusedLi]
              : styles.li;

            return (
              <li
                css={style}
                data-testid={`prediction-${index}`}
                key={prediction.place_id}
                onClick={() => handlePredictionClick(prediction.place_id)}
              >{prediction.description}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default forwardRef(AutocompleteAddressInput);

AutocompleteAddressInput.propTypes = {
  errorLabel: PropTypes.string,
  focusOnMount: PropTypes.bool,
  googleService: PropTypes.instanceOf(GoogleMapsService),
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  inputWrapperStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.string,
  maxPredictionsShown: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onPredictionNotFoundClick: PropTypes.func,
  onPredictionSelect: PropTypes.func,
  onSubmit: PropTypes.func,
  ref: PropTypes.object,
};

const styles = StyleSheet.create({
  addressInputOpen: {
    ...Theme.roundedCorners(),
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  addressInputOverflow: {
    paddingRight: 50,
    textOverflow: 'ellipsis',
  },
  focusedLi: {
    backgroundColor: Colors.rootOrange(),
    color: Colors.white(),
    cursor: 'pointer',
  },
  listContainer: {
    width: '100%',
    position: 'relative',
  },
  list: {
    ...Shadows.softShadow(),
    display: 'inline-block',
    listStyle: 'none',
    paddingLeft: 0,
    position: 'absolute',
    width: '100%',
    zIndex: ZIndex.DROPDOWN_MENU,
  },
  li: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 60,
    backgroundColor: Colors.nearWhite(),
    border: `1px solid ${Colors.gray30()}`,
    color: Colors.gray50(),
    borderTopWidth: 0,
    padding: '15px',
    ':hover': {
      backgroundColor: Colors.rootOrange(),
      color: Colors.white(),
      cursor: 'pointer',
    },
  },
  searchImage: {
    marginRight: 10,
  },
});
