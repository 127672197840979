import ExpectedNetworkError from '@root/core/src/networking/expected-network-error';
import NetworkConnectivityError from '@root/core/src/networking/network-connectivity-error';
import UnexpectedNetworkError from '@root/core/src/networking/unexpected-network-error';

export default class NetworkResult {
  constructor(data, error, rawResponse, fetchOptions) {
    this.data = data;
    this.error = error;
    this.rawResponse = rawResponse;
    this.fetchOptions = fetchOptions;
  }

  isSuccess() {
    return !this.error;
  }

  isError() {
    return !this.isSuccess();
  }

  isExpectedError() {
    return this.error instanceof ExpectedNetworkError;
  }

  isUnexpectedError() {
    return this.error instanceof UnexpectedNetworkError;
  }

  isNetworkConnectivityError() {
    return this.error instanceof NetworkConnectivityError;
  }

  isAborted() {
    return this.error?.name === 'AbortError';
  }
}
