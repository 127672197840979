import { useEffect, useRef } from '@root/vendor/react';

export default function useInputFocusOnMount(shouldFocusOnMount = true, externalRef) {
  const internalRef = useRef(null);
  const inputRef = externalRef || internalRef;

  useEffect(() => {
    let timeout;

    if (shouldFocusOnMount && inputRef.current) {
      timeout = setTimeout(() => {
        inputRef.current.focus();
      }, 300);
    }

    return () => timeout && clearTimeout(timeout);
  }, [inputRef, shouldFocusOnMount]);

  return inputRef;
}
