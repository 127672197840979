import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function EmailLoginHeading({
  heading, subtext, icon,
}) {
  return (
    <div css={styles.container}>
      {icon &&
      <img
        css={styles.icon}
        data-testid={'icon'}
        src={icon}
      />}
      <h1 css={styles.heading}>
        {heading}
      </h1>
      <p css={styles.subtext}>
        {subtext}
      </p>
    </div>
  );
}

EmailLoginHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  icon: PropTypes.any,
  subtext: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    ...Responsive.md({
      marginTop: 20,
      marginBottom: 30,
    }),
  },
  heading: {
    ...Theme.heading1(),
    whiteSpace: 'pre-line',
  },
  subtext: {
    ...Theme.paragraph1(),
    marginTop: 12,
    textAlign: 'center',
  },
});

