/**
 * The RootError class is used to report errors with better grouping to sentry.
 * @param {string} message The message body of the error.
 * @param {string} name The name of the error.
 * @param {string=} team The team the error should be reported to.
 * @param {Object.<string,string>=} tags The tags to report to sentry.
 * @param {'fatal'|'error'|'info'=} severity The severity of the error, given by Sentry.Fatal, Sentry.Error, Sentry.Info
 * @param {Array.<string>=} fingerprint An array of string values. Required if message is not a string literal.
 */

export class RootError extends Error {
  constructor({
    message, name, team, tags = {}, severity = 'error', fingerprint = [],
  } = {}) {
    super(message);
    this.name = name;
    this.tags = tags;
    this.severity = severity;
    this.team = team;
    this.fingerprint = fingerprint.length ? [...fingerprint, name] : [name, message];
  }

  addTag(key, value) {
    this.tags[key] = value;
  }
}
