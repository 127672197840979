import * as Sentry from '@root/vendor/@sentry/browser';
import AbTestContext from '@root/core/src/contexts/ab-test/ab-test-context';
import AbTestDispatchContext from '@root/core/src/contexts/ab-test/ab-test-dispatch-context';
import PropTypes from '@root/vendor/prop-types';
import { useEffect, useReducer } from '@root/vendor/react';

function abTestReducer(state, action) {
  switch (action.type) {
  case 'SET_BUCKET_FOR_AB_TEST': {
    return {
      ...state,
      [action.abTestName]: action.bucket,
    };
  }
  }
}

export default function AbTestProvider({ children, initialAbTests }) {
  const [abTests, dispatch] = useReducer(abTestReducer, initialAbTests);

  useEffect(() => {
    Sentry.setContext('abTests', abTests);
  }, [abTests]);

  return (
    <AbTestContext.Provider value={abTests}>
      <AbTestDispatchContext.Provider value={dispatch}>
        {children}
      </AbTestDispatchContext.Provider>
    </AbTestContext.Provider>
  );
}

AbTestProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialAbTests: PropTypes.object.isRequired,
};
