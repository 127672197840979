import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function createProfileAnswerSetConfiguration({ answerSet }) {
  return new NetworkRequestConfiguration({
    endpoint: 'renters/profile_answer_set',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      ...answerSet,
    },
  });
}
