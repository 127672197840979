import Cookies from '@root/vendor/js-cookie';

export default class CookieStorageService {
  static PREFIX = '__r';
  static DEFAULTS = {
    expires: 365 * 100, // 100 years should be enough...
    path: '/',
    domain: CookieStorageService._parseDomain(),
  };

  static _parseName(name) {
    return `${this.PREFIX}.${name}`;
  }

  static _parseDomain(hostname) {
    if (typeof document === 'undefined') { return; }
    hostname = hostname || document.location.hostname;

    const hostArray = hostname.split('.');
    switch (hostArray.length) {
    case 0:
      throw Error('Invalid hostname');
    case 1:
      return hostname;
    case 2:
      return `.${hostname}`;
    default:
      return `.${hostArray.slice(hostArray.length - 2).join('.')}`;
    }
  }

  static has(name) {
    return !!Cookies.get(this._parseName(name));
  }

  static get(name) {
    return Cookies.getJSON(this._parseName(name));
  }

  static set(name, value, options) {
    Cookies.set(
      this._parseName(name),
      value,
      {
        ...CookieStorageService.DEFAULTS,
        ...options,
      },
    );
  }

  static unset(name, options) {
    Cookies.remove(
      this._parseName(name),
      {
        ...CookieStorageService.DEFAULTS,
        ...options,
      }
    );
  }
}
