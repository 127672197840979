import LoginEntry from '@root/renters.joinroot.com/src/login/components/entry';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import RentersRestrictedFlowEntry from '@root/renters.joinroot.com/src/components/restricted-flow-entry';
import Routes from '@root/renters.joinroot.com/src/routes/protected-routes';
import { Route, Switch } from '@root/core/src/components/route';

export default function ProtectedRouter({ passProps }) {
  return (
    <Switch>
      <Route
        path={Routes.LOGIN}
        render={() => <LoginEntry passProps={passProps} />}
      />
      <Route
        path={'(.*)'}
        render={() => <RentersRestrictedFlowEntry passProps={passProps} />}
      />
    </Switch>
  );
}

ProtectedRouter.propTypes = {
  passProps: PropTypes.object.isRequired,
};
