import ReactDOM from '@root/vendor/react-dom';
import { useEffect, useRef } from '@root/vendor/react';

export default function usePortal(id) {
  const ref = useRef();

  useEffect(() => {
    return () => {
      ref.current?.parentNode && ref.current.parentNode.removeChild(ref.current);
    };
  }, [id]);

  return function renderPortal(component) {
    if (!ref.current) {
      const portalNode = document.createElement('div');
      portalNode.dataset.testid = id;

      ref.current = portalNode;

      document.body.appendChild(portalNode);
    }

    return ReactDOM.createPortal(component, ref.current);
  };
}
