import rootSansBlackItalicWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-12BlackItalic.woff';
import rootSansBlackItalicWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-12BlackItalic.woff2';
import rootSansBlackWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-11Black.woff';
import rootSansBlackWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-11Black.woff2';
import rootSansBoldItalicWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-10BoldItalic.woff';
import rootSansBoldItalicWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-10BoldItalic.woff2';
import rootSansBoldWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-9Bold.woff';
import rootSansBoldWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-9Bold.woff2';
import rootSansLightItalicWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-4LightItalic.woff';
import rootSansLightItalicWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-4LightItalic.woff2';
import rootSansLightWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-3Light.woff';
import rootSansLightWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-3Light.woff2';
import rootSansRegularItalicWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-6Italic.woff';
import rootSansRegularItalicWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-6Italic.woff2';
import rootSansRegularWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-5Regular.woff';
import rootSansRegularWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-5Regular.woff2';
import rootSansSemiBoldItalicWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-8SemiBoldItalic.woff';
import rootSansSemiBoldItalicWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-8SemiBoldItalic.woff2';
import rootSansSemiBoldWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-7SemiBold.woff';
import rootSansSemiBoldWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-7SemiBold.woff2';
import rootSansThinItalicWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-2ThinItalic.woff';
import rootSansThinItalicWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-2ThinItalic.woff2';
import rootSansThinWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-1Thin.woff';
import rootSansThinWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-1Thin.woff2';
import rootSansUltraItalicWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-14UltraItalic.woff';
import rootSansUltraItalicWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-14UltraItalic.woff2';
import rootSansUltraWoff from '@root/brand/src/assets/fonts/root-sans/RootSans-13Ultra.woff';
import rootSansUltraWoff2 from '@root/brand/src/assets/fonts/root-sans/RootSans-13Ultra.woff2';

export default class RootSans {
  static fontFamily = 'Root Sans';

  static base = {
    fontFamily: RootSans.fontFamily,
    fontDisplay: 'swap',
  };

  static thin() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'normal',
          fontWeight: 200,
          src: `url('${rootSansThinWoff2}') format('woff2'), url('${rootSansThinWoff}') format('woff')`,
        },
      ],
      fontWeight: 200,
    };
  }

  static thinItalic() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'italic',
          fontWeight: 200,
          src: `url('${rootSansThinItalicWoff2}') format('woff2'), url('${rootSansThinItalicWoff}') format('woff')`,
        },
      ],
      fontWeight: 200,
    };
  }

  static light() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'normal',
          fontWeight: 300,
          src: `url('${rootSansLightWoff2}') format('woff2'), url('${rootSansLightWoff}') format('woff')`,
        },
      ],
      fontWeight: 300,
    };
  }

  static lightItalic() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'italic',
          fontWeight: 300,
          src: `url('${rootSansLightItalicWoff2}') format('woff2'), url('${rootSansLightItalicWoff}') format('woff')`,
        },
      ],
      fontWeight: 300,
    };
  }

  static regular() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'normal',
          fontWeight: 400,
          src: `url('${rootSansRegularWoff2}') format('woff2'), url('${rootSansRegularWoff}') format('woff')`,
        },
      ],
      fontWeight: 400,
    };
  }

  static regularItalic() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'italic',
          fontWeight: 400,
          src: `url('${rootSansRegularItalicWoff2}') format('woff2'), url('${rootSansRegularItalicWoff}') format('woff')`,
        },
      ],
      fontWeight: 400,
    };
  }

  static semiBold() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'normal',
          fontWeight: 600,
          src: `url('${rootSansSemiBoldWoff2}') format('woff2'), url('${rootSansSemiBoldWoff}') format('woff')`,
        },
      ],
      fontWeight: 600,
    };
  }

  static semiBoldItalic() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'italic',
          fontWeight: 600,
          src: `url('${rootSansSemiBoldItalicWoff2}') format('woff2'), url('${rootSansSemiBoldItalicWoff}') format('woff')`,
        },
      ],
      fontWeight: 600,
    };
  }

  static bold() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'normal',
          fontWeight: '700',
          src: `url('${rootSansBoldWoff2}') format('woff2'), url('${rootSansBoldWoff}') format('woff')`,
        },
      ],
      fontWeight: '700',
    };
  }

  static boldItalic() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'italic',
          fontWeight: 700,
          src: `url('${rootSansBoldItalicWoff2}') format('woff2'), url('${rootSansBoldItalicWoff}') format('woff')`,
        },
      ],
      fontWeight: 700,
    };
  }

  static black() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'normal',
          src: `url('${rootSansBlackWoff2}') format('woff2'), url('${rootSansBlackWoff}') format('woff')`,
          fontWeight: '800',
        },
      ],
      fontWeight: '800',
    };
  }

  static blackItalic() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'italic',
          fontWeight: 800,
          src: `url('${rootSansBlackItalicWoff2}') format('woff2'), url('${rootSansBlackItalicWoff}') format('woff')`,
        },
      ],
      fontWeight: 800,
    };
  }

  static ultra() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'normal',
          fontWeight: 900,
          src: `url('${rootSansUltraWoff2}') format('woff2'), url('${rootSansUltraWoff}') format('woff')`,
        },
      ],
      fontWeight: 900,
    };
  }

  static ultraItalic() {
    return {
      fontFamily: [
        {
          ...RootSans.base,
          fontStyle: 'italic',
          fontWeight: 900,
          src: `url('${rootSansUltraItalicWoff2}') format('woff2'), url('${rootSansUltraItalicWoff}') format('woff')`,
        },
      ],
      fontWeight: 900,
    };
  }
}
