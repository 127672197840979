import { RootError } from '@root-common/root-errors';

export const CLAIMS_PHONE = '(866) 980-9431';
export const ROADSIDE_PHONE = '(855) 982-1720';
export const SUPPORT_PHONE = '(866) 980-9431';
export const SUPPORT_PHONE_BOOMERANG_INELIGIBLE = '(833) 329-1076';

export const PHONE_NUMBER_MASK = ['(', /[2-9]/, /[0-8]/, /\d/, ')', ' ', /[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const NO_PHONE_NUMBER_MASK = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const ALTERNATE_PHONE_NUMBER_MASK = [/[2-9]/, /[0-8]/, /\d/, '-', /[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const VALID_PHONE_NUMBER_REGEX = /^([2-9][0-8]\d[2-9]\d{6})$/;
export const VALID_PHONE_NUMBER_REGEX_WITH_FORMATTING = /[(][2-9][0-8]\d[)] [2-9]\d{2}[-]\d{4}/;

export const cleanPhoneNumberValue = (rawValue) => {
  let newValue = rawValue.replace(/\D/g, '');
  newValue = _cleanFrontDigits(newValue);
  if (newValue.length > 10) { newValue = newValue.substr(0, 10); }
  return newValue;
};

const _cleanFrontDigits = (v) => {
  let newV = `${v}`;

  while (newV[0] === '0') { newV = newV.substr(1); }
  if (newV[0] === '1' && newV.length > 10) { newV = newV.substr(1); }

  return newV === v ? newV : _cleanFrontDigits(newV);
};

export const tenDigitsToHumanDashesFormat = (tenDigitPhoneNumber) => {
  if (tenDigitPhoneNumber.length !== 10 || isNaN(tenDigitPhoneNumber)) {
    throw new RootError({
      message: `Incorrectly formatted phone number passed to tenDigitsToHumanDashesFormat: ${tenDigitPhoneNumber}`,
      name: 'IncorrectPhoneNumberError',
      fingerprint: ['IncorrectPhoneNumberError'],
    });
  }
  return `${tenDigitPhoneNumber.substring(0, 3)}-${tenDigitPhoneNumber.substring(3, 6)}-${tenDigitPhoneNumber.substring(6)}`;
};

export const maskedPhoneNumber = (tenDigitPhoneNumber) => {
  if (tenDigitPhoneNumber.length !== 10 || isNaN(tenDigitPhoneNumber)) {
    throw new RootError({
      message: `Incorrectly formatted phone number passed to maskedPhoneNumber: ${tenDigitPhoneNumber}`,
      name: 'IncorrectPhoneNumberError',
      fingerprint: ['IncorrectPhoneNumberError'],
    });
  }
  return `(***) ***-${tenDigitPhoneNumber.substring(6)}`;
};
