import AnalyticsService from '@root/core/src/services/analytics-service';
import AuthService from '@root/core/src/services/auth-service';
import CaptchaService from '@root/core/src/services/captcha-service';
import getSignupConfiguration from '@root/renters.joinroot.com/src/api/signup-configuration';
import reportAccountCreatedEventConfiguration from '@root/attribution/src/api/report-account-created-event/report-account-created-event-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-safe-imperative-network-request';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';
import { useState } from '@root/vendor/react';

export default function useSignup() {
  const [isSignedUp, setIsSignedUp] = useState();
  const [signup] = useImperativeNetworkRequest(getSignupConfiguration);
  const [trackAccountCreatedEvent] = useSafeImperativeNetworkRequest(reportAccountCreatedEventConfiguration);
  const rootId = useRootId();

  async function performSignup(signupParams) {
    const captchaToken = await CaptchaService.challenge('Renters_Signup');
    const result = await signup({
      ...signupParams,
      captchaToken,
      rootId,
    });
    if (result.isSuccess()) {
      AuthService.setAccessToken(result.data.accessToken);
      AuthService.setCurrentUserContext({
        accountId: result.data.accountId,
        userId: result.data.userId,
      });
      await trackAccountCreatedEvent({
        rootId,
      });

      AnalyticsService.alias(result.data.userId);

      setIsSignedUp(true);
    }
  }

  return {
    isSignedUp,
    signup: performSignup,
  };
}
