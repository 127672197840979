import AutocompleteAddressInput from '@root/core/src/components/autocomplete-address-input';
import Button from '@root/core/src/components/button';
import CSSTransition from '@root/vendor/react-transition-group/CSSTransition';
import GoogleMapsService from '@root/core/src/services/google-maps-service';
import Input from '@root/core/src/components/input';
import InputError from '@root/core/src/components/input-error';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import caret from '@root/core/src/assets/caret.svg';
import { AnimationStyleSheet, Colors, StyleSheet } from '@root/core/src/utils/styles';
export default function AutocompleteAddressForm({
  googleService,
  focusOnMount = false,
  autocompleteInputStyle,
  autocompleteInputWrapperStyle,
  buildAddress,
  address1Label = 'Address, city, state, ZIP',
  onAutocompletePredictionSelect = () => {},
  onAutocompleteChange = () => {},
  onAutocompleteFocus = () => {},
  onPredictionNotFoundClick,
  onSubmit = () => {},
  tipText,
  buttonStyles,
  ctaText = 'Continue',
}) {
  const [autocompleteInputFocused, setAutocompleteInputFocused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [address2Value, setAddress2Value] = useState('');
  const [selectedGooglePlace, setSelectedGooglePlace] = useState();
  const [addressFieldIsDirty, setAddressFieldIsDirty] = useState(true);

  const mounted = useRef(true);

  useEffect(() => () => {
    mounted.current = false;
  }, [mounted]);

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const address = buildAddress(selectedGooglePlace, address2Value);

    if (!address.isValid) {
      setError('Address is invalid. Enter a number, followed by a street name.');
      setIsSubmitting(false);
      return;
    }

    await onSubmit(address);

    if (mounted.current) {
      setIsSubmitting(false);
    }
  };

  const handleAutocompleteFocused = (value) => {
    setAutocompleteInputFocused(true);
    onAutocompleteFocus(value);
  };

  const handleAutocompletePredictionSelect = (googlePlace) => {
    setAddressFieldIsDirty(false);
    onAutocompletePredictionSelect(googlePlace);
    setSelectedGooglePlace(googlePlace);
    setError(null);
  };

  const handleAutocompleteChanged = (value) => {
    setAddressFieldIsDirty(true);
    onAutocompleteChange(value);
  };

  return (
    <>
      <form
        onSubmit={handleOnSubmit}
      >
        <AutocompleteAddressInput
          focusOnMount={focusOnMount}
          googleService={googleService}
          inputStyle={autocompleteInputStyle}
          inputWrapperStyle={autocompleteInputWrapperStyle}
          label={address1Label}
          onChange={handleAutocompleteChanged}
          onFocus={handleAutocompleteFocused}
          onPredictionNotFoundClick={onPredictionNotFoundClick}
          onPredictionSelect={handleAutocompletePredictionSelect}
        />
        {error && <InputError message={error} />}
        <CSSTransition
          classNames={fadeInAnimation}
          in={autocompleteInputFocused}
          mountOnEnter={true}
          timeout={200}
          unmountOnExit={true}
        >
          <div css={styles.address2Container}>
            <Input
              inputStyle={autocompleteInputStyle}
              label={'Apartment, unit, or suite (optional)'}
              onChange={setAddress2Value}
              value={address2Value}
              wrapperStyle={autocompleteInputWrapperStyle}
            />
          </div>
        </CSSTransition>
        <CSSTransition
          classNames={tipInAnimation}
          in={autocompleteInputFocused && !!tipText}
          mountOnEnter={true}
          timeout={200}
          unmountOnExit={true}
        >
          <div
            css={styles.tipContainer}
            data-testid={'tip'}
          >
            <div css={styles.tipContent}>
              <img
                css={styles.tipIcon}
                src={caret}
              />
              <div css={styles.tip}>{tipText}</div>
            </div>
          </div>
        </CSSTransition>
        <Button
          css={[styles.continueButton, buttonStyles]}
          disabled={!!error || isSubmitting || addressFieldIsDirty || !selectedGooglePlace}
        >
          {ctaText}
        </Button>
      </form>
    </>
  );
}

AutocompleteAddressForm.propTypes = {
  address1Label: PropTypes.string,
  autocompleteInputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  autocompleteInputWrapperStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  buildAddress: PropTypes.func.isRequired,
  buttonStyles: PropTypes.object,
  ctaText: PropTypes.string,
  focusOnMount: PropTypes.bool,
  googleService: PropTypes.instanceOf(GoogleMapsService),
  onAutocompleteChange: PropTypes.func,
  onAutocompleteFocus: PropTypes.func,
  onAutocompletePredictionSelect: PropTypes.func,
  onPredictionNotFoundClick: PropTypes.func,
  onSubmit: PropTypes.func,
  tipText: PropTypes.string,
};

const fadeInAnimation = AnimationStyleSheet.createClassNames({
  enter: {
    opacity: 0,
    transform: 'translateY(0)',
  },
  enterActive: {
    opacity: 1,
    transform: 'translateY(-3px)',
    transition: 'all 100ms linear',
  },
});

const tipInAnimation = AnimationStyleSheet.createClassNames({
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
    transition: 'all 200ms linear 300ms',
  },
});

const styles = StyleSheet.create({
  address2Container: {
    marginTop: 24,
    marginBottom: 24,
  },
  continueButton: {
    borderRadius: 6,
    height: 60,
    marginBottom: 12,
    marginTop: 48,
    ...Responsive.lessThanSm({
      height: 48,
    }),
  },
  form: {
    position: 'absolute',
    width: '100%',
  },
  tipContainer: {
    overflow: 'hidden',
    height: 'auto',
  },
  tipContent: {
    display: 'flex',
  },
  tip: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '18px',
    letterSpacing: '-0.1px',
    color: Colors.gray50(),
  },
  tipIcon: {
    marginTop: 1,
    marginRight: 16,
    height: 24,
    width: 24,
    minWidth: 24,
    minHeight: 24,
  },
});
