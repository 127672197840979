import DobDate from '@root/core/src/models/dob-date';
import Vin from '@root/core/src/models/vin';
import capitalize from '@root/vendor/lodash/capitalize';
import template from '@root/vendor/lodash/template';
import { VALID_PHONE_NUMBER_REGEX } from '@root/core/src/models/phone';

export const STANDARD_PASSWORD_MINIMUM_LENGTH = 6;
export const LONG_PASSWORD_MINIMUM_LENGTH = 8;
export const EMAIL_REGEX = new RegExp('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$');
export const LOB_TEST_ADDRESSES = ['residential highrise', 'commercial highrise', 'deliverable', 'missing unit', 'incorrect unit', 'undeliverable block match'];
const US_STREET_ADDRESS_REGEX = '^(\\s*[a-zA-Z]?\\d+[a-zA-Z]?\\s+\\w)';
const STREET_AND_LOB_TEST_COMBINED_REGEX = US_STREET_ADDRESS_REGEX.concat(LOB_TEST_ADDRESSES.map((testAddress) => `|(${testAddress})`).join(''));
export const ADDRESS_LINE_ONE_REGEX = new RegExp(STREET_AND_LOB_TEST_COMBINED_REGEX);
export const SHORT_ZIP_REGEX = new RegExp('^\\d{5}$');
export const LONG_ZIP_REGEX = new RegExp('^\\d{5}(?:-?\\d{4})?$');
const PHONE_NUMBER_FIELD_NAME = 'phoneNumber';
const FIRST_NAME_FIELD_NAME = 'firstName';
const LAST_NAME_FIELD_NAME = 'lastName';
export const FIRST_NAME_REGEX = new RegExp(/[0-9]/);
export const LAST_NAME_REGEX = new RegExp(/((?=\D)\S)+/);
export const NON_EMPTY_STRING_REGEX = new RegExp(/\S/);
export const MESSAGE_TEMPLATE = {
  REQUIRED: '${fieldName} cannot be blank',
  IS_INVALID: '${fieldName} is not valid',
  IS_VALID_PASSWORD: '${fieldName} is not valid',
  IS_VALID_DATE: '${fieldName} is not a valid date',
  IS_CONFIRMED: 'Confirmation must match ${fieldName}',
  IS_INVALID_PHONE_NUMBER: 'Phone number is not valid',
  IS_INVALID_FIRST_NAME: 'First name cannot contain numbers',
  EXCEEDS_MAXIMUM_LENGTH: '${fieldName} is too long',
  IS_INVALID_RESET_PASSWORD: 'New password must be different from the old password',
  IS_INVALID_UPDATE_EMAIL: 'New email address must be different from the current email address',
  IS_INVALID_ZIP: 'ZIP code is not valid',
};

export function isRequired(fieldName, messageTemplate = MESSAGE_TEMPLATE.REQUIRED) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (!fieldValue || typeof fieldValue === 'string' && !fieldValue.trim()) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isValidPhoneNumber(fieldName = PHONE_NUMBER_FIELD_NAME, messageTemplate = MESSAGE_TEMPLATE.IS_INVALID_PHONE_NUMBER) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && !VALID_PHONE_NUMBER_REGEX.test(fieldValue)) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isValidEmail(fieldName, messageTemplate = MESSAGE_TEMPLATE.IS_INVALID) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && !EMAIL_REGEX.test(fieldValue)) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isValidZip(fieldName, zipPattern = SHORT_ZIP_REGEX, messageTemplate = MESSAGE_TEMPLATE.IS_INVALID_ZIP) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && !zipPattern.test(fieldValue)) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isValidVin(fieldName) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue) {
      const { isValid: vinIsValid, error: vinError } = Vin.validate(fieldValue);
      if (!vinIsValid) {
        errors[fieldName] = formatErrorMessage(fieldName, fieldValue, vinError);
      }
    }
  };
}

export function isValid(fieldName, messageTemplate = MESSAGE_TEMPLATE.IS_VALID_DATE) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && !fieldValue.isValid()) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isOldEnough(fieldName, messageTemplate = MESSAGE_TEMPLATE.IS_VALID_DATE, currentDate = new Date()) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && !fieldValue.isOldEnough({
      currentDate,
    })) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isYoungEnough(fieldName, messageTemplate = MESSAGE_TEMPLATE.IS_VALID_DATE, currentDate = new Date()) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && !fieldValue.isYoungEnough({
      currentDate,
    })) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isValidAge(fieldName, { maxAge = DobDate.DEFAULT_MAX_AGE, minAge = DobDate.DEFAULT_MIN_AGE } = {}) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && !fieldValue.isYoungEnough({
      maxAge,
    })) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, `You must be ${maxAge} years or younger to sign up!`);
    }
    if (fieldValue && !fieldValue.isOldEnough({
      minAge,
    })) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, `You must be ${minAge} years or older to sign up!`);
    }
  };
}

export function isNotOlderThan(fieldName, compareFieldName, messageTemplate = MESSAGE_TEMPLATE.IS_VALID_DATE) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    const compareFieldValue = object[compareFieldName].age();
    if (fieldValue && !fieldValue.isNotOlderThan(compareFieldValue)) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export const isValidSignUpPassword = isValidPassword(STANDARD_PASSWORD_MINIMUM_LENGTH);
export const isValidLongSignUpPassword = isValidPassword(LONG_PASSWORD_MINIMUM_LENGTH);

export function isConfirmed(fieldName, messageTemplate = MESSAGE_TEMPLATE.IS_CONFIRMED) {
  return (object, errors) => {
    const confirmationFieldName = `${fieldName}Confirmation`;
    const fieldValue = object[fieldName];
    const fieldConfirmationValue = object[confirmationFieldName];
    if (fieldConfirmationValue && fieldValue !== fieldConfirmationValue) {
      errors[confirmationFieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isFormatted(fieldName, regexp, messageTemplate = MESSAGE_TEMPLATE.IS_INVALID) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (regexp.test(fieldValue) === false) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

function formatErrorMessage(fieldName, fieldValue, errorMessageTemplate) {
  const compiled = template(errorMessageTemplate);
  return compiled({
    fieldName: capitalize(fieldName.replace(/([A-Z])/g, ' $1')),
    fieldValue,
  });
}

export function isValidResetPassword(currentPasswordFieldName, newPasswordFieldName, messageTemplate = MESSAGE_TEMPLATE.IS_INVALID_RESET_PASSWORD) {
  return (object, errors) => {
    const currentPassword = object[currentPasswordFieldName];
    const newPassword = object[newPasswordFieldName];
    if (currentPassword && newPassword && currentPassword === newPassword) {
      errors[newPasswordFieldName] = formatErrorMessage(newPasswordFieldName, newPassword, messageTemplate);
    }
  };
}

function isValidPassword(minLength) {
  return (fieldName, messageTemplate = MESSAGE_TEMPLATE.IS_VALID_PASSWORD) => (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && fieldValue.length < minLength) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isTrue(fieldName, messageTemplate = MESSAGE_TEMPLATE.IS_INVALID) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue !== true) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isValidFirstName(fieldName = FIRST_NAME_FIELD_NAME, messageTemplate = MESSAGE_TEMPLATE.IS_INVALID_FIRST_NAME) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && FIRST_NAME_REGEX.test(fieldValue)) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}
export function isValidLastName(fieldName = LAST_NAME_FIELD_NAME, messageTemplate = MESSAGE_TEMPLATE.IS_INVALID) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && !LAST_NAME_REGEX.test(fieldValue)) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function hasMaximumLength(fieldName, maxLength, messageTemplate = MESSAGE_TEMPLATE.EXCEEDS_MAXIMUM_LENGTH) {
  return (object, errors) => {
    const fieldValue = object[fieldName];
    if (fieldValue && fieldValue.length > maxLength) {
      errors[fieldName] = formatErrorMessage(fieldName, fieldValue, messageTemplate);
    }
  };
}

export function isValidUpdateEmail(currentEmailFieldName, newEmailFieldName, messageTemplate = MESSAGE_TEMPLATE.IS_INVALID_UPDATE_EMAIL) {
  return (object, errors) => {
    const currentEmail = object[currentEmailFieldName];
    const newEmail = object[newEmailFieldName];
    if (currentEmail && newEmail && currentEmail === newEmail) {
      errors[newEmailFieldName] = formatErrorMessage(newEmailFieldName, newEmail, messageTemplate);
    }
  };
}
