import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import {
  BUTTON_BASE_STYLES,
  BUTTON_ICON_POSITIONS,
  BUTTON_ICON_POSITION_LEFT,
  BUTTON_ICON_POSITION_STYLES,
  BUTTON_ICON_STYLESHEET,
  BUTTON_SIZES,
  BUTTON_SIZE_REGULAR,
  BUTTON_SIZE_STYLES,
  BUTTON_VARIANTS,
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_STYLES,
} from '@root/core/src/components/button/styles';
import { styled } from '@root/core/src/utils/styles';

const BaseButton = ({
  buttonRef,
  children,
  className,
  disabled,
  iconComponent: IconComponent,
  iconPosition = BUTTON_ICON_POSITION_LEFT,
  onClick = () => {},
  passProps,
  tag: Tag = 'button',
  type = 'submit',
}) => {
  const renderIcon = () => IconComponent && (
    <IconComponent
      iconStyles={[
        BUTTON_ICON_STYLESHEET.icon,
        BUTTON_ICON_STYLESHEET[iconPosition],
      ]}
    />
  );

  const baseProps = {
    ...passProps,
    className,
    disabled,
    onClick,
    ref: buttonRef,
    type,
  };

  const linkRemoveProps = ['type'];
  const linkProps = Object.keys(baseProps)
    .filter((key) => !linkRemoveProps.includes(key))
    .reduce((obj, key) => {
      obj[key] = baseProps[key];
      return obj;
    }, {});

  const props = Tag === 'a' ? linkProps : baseProps;

  return (
    <Tag
      {...props}
    >
      {renderIcon()}
      {children}
    </Tag>
  );
};

const RootButton = styled(BaseButton)(({
  variant = BUTTON_VARIANT_PRIMARY,
  size = BUTTON_SIZE_REGULAR,
  iconPosition = BUTTON_ICON_POSITION_LEFT,
  iconComponent,
}) =>
  [
    BUTTON_BASE_STYLES,
    iconComponent && BUTTON_ICON_POSITION_STYLES[iconPosition],
    BUTTON_VARIANT_STYLES[variant],
    BUTTON_SIZE_STYLES[size],
  ],
);

export const Button = React.forwardRef((props, ref) => (
  <RootButton
    {...props}
    buttonRef={ref}
  />
));

BaseButton.propTypes = {
  buttonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.bool,
  ]),
  iconPosition: PropTypes.oneOf(BUTTON_ICON_POSITIONS),
  onClick: PropTypes.func,
  passProps: PropTypes.object,
  size: PropTypes.oneOf(BUTTON_SIZES),
  styles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  tag: PropTypes.oneOf(['button', 'a']),
  type: PropTypes.oneOf(['submit', 'button']),
  variant: PropTypes.oneOf(BUTTON_VARIANTS),
};
