import KeyCode from '@root/core/src/utils/keycode';
import { useCallback, useEffect } from '@root/vendor/react';

export default function useModal(onClose, portalRef) {
  const handleKeydown = useCallback((event) => {
    if (event.keyCode === KeyCode.KEYCODES.ESC) {
      onClose();
    }
  }, [onClose]);

  const handleClickOutsideModal = useCallback((e) => {
    if (portalRef.current && !portalRef.current.contains(e.target)) {
      onClose();
    }
  }, [onClose, portalRef]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, false);
    document.addEventListener('mousedown', handleClickOutsideModal);

    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, [handleClickOutsideModal, handleKeydown]);
}
