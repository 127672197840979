import GooglePlace from '@root/core/src/models/google-place';
import { useEffect, useRef, useState } from '@root/vendor/react';

export const CANT_FIND_ADDRESS_PREDICTION = {
  description: 'Can’t find your address?',
  place_id: 'cant-find-address',
};

export default function useAutocompleteAddressInput(googleService, maxPredictionsShown = 5, onPredictionNotFoundClick) {
  const [value, setValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [focusedPredictionIndex, setFocusedPredictionIndex] = useState(0);
  const [selectedGooglePlace, setSelectedGooglePlace] = useState();
  const timeout = useRef();

  useEffect(() => () => {
    clearTimeout(timeout.current);
  });

  const handleBlur = () => {
    timeout.current = setTimeout(() => {
      setPredictions([]);
      setFocusedPredictionIndex(0);
    }, 300);
  };

  const handleSetPredictions = (placePredictions) => {
    const shouldAddCantFindAddressPrediction = onPredictionNotFoundClick && !placePredictions.includes(CANT_FIND_ADDRESS_PREDICTION);

    if (!shouldAddCantFindAddressPrediction) {
      setPredictions(placePredictions);
      return;
    }

    const newPredictions = placePredictions;
    newPredictions.splice(maxPredictionsShown - 1, 0, CANT_FIND_ADDRESS_PREDICTION);
    setPredictions(newPredictions);
  };

  const handleChange = (inputValue) => {
    setSelectedGooglePlace(null);

    if (inputValue) {
      googleService.getPlacePredictions(inputValue, handleSetPredictions);
      setValue(inputValue);
      return;
    }

    setPredictions([]);
    setFocusedPredictionIndex(0);
    setValue('');
  };

  const handleFocus = (inputValue) => {
    if (inputValue) { googleService.getPlacePredictions(inputValue, handleSetPredictions); }
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
    case 'ArrowUp':
      event.preventDefault();

      if (focusedPredictionIndex > 0) {
        setFocusedPredictionIndex(focusedPredictionIndex - 1);
      }

      break;
    case 'ArrowDown':
      event.preventDefault();

      if (focusedPredictionIndex < predictions.length - 1) {
        setFocusedPredictionIndex(focusedPredictionIndex + 1);
      }

      break;
    default:
      break;
    }
  };

  const createEnterKeyPressHandler = (callback = () => {}) => (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    if (predictions.length) {
      const placeId = predictions[focusedPredictionIndex].place_id;
      setPredictions([]);
      setFocusedPredictionIndex(0);

      if (placeId === CANT_FIND_ADDRESS_PREDICTION.place_id && onPredictionNotFoundClick) {
        onPredictionNotFoundClick(value);
        return;
      }

      googleService.getPlaceDetails(placeId, (details) => {
        const googlePlace = new GooglePlace(details);
        setValue(googlePlace.formattedAddress);
        setSelectedGooglePlace(googlePlace);
        callback(googlePlace);
      });
      event.preventDefault();
    } else if (!selectedGooglePlace) {
      event.preventDefault();
    }
  };

  const createClickHandler = (callback = () => {}) => (placeId) => {
    setPredictions([]);
    setFocusedPredictionIndex(0);

    if (placeId === CANT_FIND_ADDRESS_PREDICTION.place_id && onPredictionNotFoundClick) {
      onPredictionNotFoundClick(value);
      return;
    }

    googleService.getPlaceDetails(placeId, (details) => {
      const googlePlace = new GooglePlace(details);
      setValue(googlePlace.formattedAddress);
      setSelectedGooglePlace(googlePlace);
      callback(googlePlace);
    });
  };

  const fieldProps = ({
    onFocus = () => {},
    onChange = () => {},
  }) => {
    return {
      onBlur: handleBlur,
      onChange: (v) => {
        handleChange(v);
        onChange(v);
      },
      onFocus: (v) => {
        handleFocus(v);
        onFocus(v);
      },
      onKeyDown: handleKeyDown,
      value,
    };
  };

  return {
    createClickHandler,
    createEnterKeyPressHandler,
    fieldProps,
    predictions: predictions.map((p, index) => {
      p.isFocused = index === focusedPredictionIndex;
      return p;
    }),
    selectedGooglePlace,
  };
}
