import CaretLoader from '@root/core/src/components/caret-loader';
import Header from '@root/core/src/components/header';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import { Layout } from '@root/brand/src/utils/layout';
import { StyleSheet } from '@root/core/src/utils/styles';

export const LOADER_DEFAULTS = {
  timeout: 500,
};

export default function SceneLoader({ hideHeader, timeout = LOADER_DEFAULTS.timeout }) {
  const [isShowing, setIsShowing] = useState();

  useEffect(() => {
    const isShowingTimeout = setTimeout(() => {
      setIsShowing(true);
    }, timeout);

    return () => clearTimeout(isShowingTimeout);
  }, [timeout]);

  return (
    <div
      css={styles.container}
      key={'scene-loader-container'}
    >
      {!hideHeader && <Header />}
      <div
        css={[styles.loaderContainer, hideHeader && styles.loaderContainerHideHeader]}
        key={'scene-loader'}
      >
        {isShowing && <CaretLoader />}
      </div>
    </div>
  );
}

SceneLoader.propTypes = {
  hideHeader: PropTypes.bool,
  timeout: PropTypes.number,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 auto',
    height: '100%',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${Layout.navbarHeight()}px)`,
  },
  loaderContainerHideHeader: {
    minHeight: '100vh',
  },
});
