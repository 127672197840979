import StorageService from '@root/core/src/services/storage-service';

export default class AuthService {
  static ACCESS_TOKEN = 'core/accessToken';
  static CURRENT_USER_CONTEXT = 'core/currentUserContext';

  static setAccessToken(accessToken) {
    return StorageService.setItem(this.ACCESS_TOKEN, accessToken);
  }

  static getAccessToken() {
    return StorageService.getItem(this.ACCESS_TOKEN);
  }

  static setCurrentUserContext(currentUserContext) {
    return StorageService.setItem(this.CURRENT_USER_CONTEXT, currentUserContext);
  }

  static getCurrentUserContext() {
    return StorageService.getItem(this.CURRENT_USER_CONTEXT) || {};
  }

  static setCurrentUserContextEmail(email) {
    return StorageService.setItem(this.CURRENT_USER_CONTEXT, {
      ...this.getCurrentUserContext(),
      email,
    });
  }

  static clear() {
    StorageService.removeItem(this.ACCESS_TOKEN);
    StorageService.removeItem(this.CURRENT_USER_CONTEXT);
  }
}
