import AutocompleteAddressForm from '@root/core/src/components/autocomplete-address-form';
import GoogleMapsService from '@root/core/src/services/google-maps-service';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import RiskAddress from '@root/renters.joinroot.com/src/models/risk-address';

function buildRiskAddress(googlePlace, address2) {
  return RiskAddress.buildFromData({
    address1: googlePlace.line1,
    address2,
    city: googlePlace.city,
    state: googlePlace.stateCode,
    zip: googlePlace.zip,
  });
}

export default function RentersAutocompleteAddressForm({
  googleService,
  autocompleteInputStyle,
  autocompleteInputWrapperStyle,
  buildAddress = buildRiskAddress,
  onAutocompletePredictionSelect = () => {},
  onAutocompleteChange = () => {},
  onAutocompleteFocus = () => {},
  onSubmit = () => {},
  tipText,
}) {
  return (
    <AutocompleteAddressForm
      autocompleteInputStyle={autocompleteInputStyle}
      autocompleteInputWrapperStyle={autocompleteInputWrapperStyle}
      buildAddress={buildAddress}
      googleService={googleService}
      onAutocompleteChange={onAutocompleteChange}
      onAutocompleteFocus={onAutocompleteFocus}
      onAutocompletePredictionSelect={onAutocompletePredictionSelect}
      onSubmit={onSubmit}
      tipText={tipText}
    />
  );
}

RentersAutocompleteAddressForm.propTypes = {
  autocompleteInputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  autocompleteInputWrapperStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  buildAddress: PropTypes.func,
  googleService: PropTypes.instanceOf(GoogleMapsService),
  onAutocompleteChange: PropTypes.func,
  onAutocompleteFocus: PropTypes.func,
  onAutocompletePredictionSelect: PropTypes.func,
  onSubmit: PropTypes.func,
  tipText: PropTypes.string,
};
