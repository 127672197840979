import Colors from '@root/brand/src/utils/colors';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import { regular, regularItalic, semiBold, semiBoldItalic } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default class Theme {
  static displayHeading() {
    return {
      ...semiBold(),
      fontSize: 42,
      lineHeight: '100%',
      letterSpacing: -2,
      color: Colors.nearBlack(),
      ...Responsive.md({
        fontSize: 66,
      }),
    };
  }

  static displayHeading2() {
    return {
      ...semiBold(),
      fontSize: 36,
      lineHeight: '100%',
      letterSpacing: -2,
      color: Colors.nearBlack(),
      ...Responsive.md({
        fontSize: 48,
      }),
    };
  }

  static displayHeading3() {
    return {
      ...semiBold(),
      fontSize: 32,
      lineHeight: '100%',
      letterSpacing: -2,
      color: Colors.nearBlack(),
      ...Responsive.lg({
        fontSize: 34,
      }),
    };
  }

  static heading1() {
    return {
      ...semiBold(),
      color: Colors.nearBlack(),
      lineHeight: '110%',
      fontSize: 28,
      letterSpacing: -1,
      ...Responsive.md({
        fontSize: 32,
      }),
    };
  }

  static heading2() {
    return {
      ...semiBold(),
      color: Colors.nearBlack(),
      lineHeight: '110%',
      fontSize: 25,
      letterSpacing: -1,
      ...Responsive.md({
        fontSize: 28,
      }),
    };
  }

  static heading3() {
    return {
      ...semiBold(),
      fontSize: 22,
      lineHeight: '130%',
      color: Colors.nearBlack(),
      letterSpacing: -1,
      ...Responsive.md({
        fontSize: 25,
      }),
    };
  }

  static heading4() {
    return {
      ...regular(),
      fontSize: 20,
      lineHeight: '130%',
      color: Colors.nearBlack(),
      letterSpacing: -0.15,
    };
  }

  static heading5() {
    return {
      ...regular(),
      fontSize: 18,
      lineHeight: '130%',
      color: Colors.nearBlack(),
      letterSpacing: -0.15,
    };
  }

  static paragraph1({ bold = false, italic = false } = {}) {
    return {
      ...regular(),
      ...bold && semiBold(),
      ...italic && regularItalic(),
      ...bold && italic && semiBoldItalic(),
      fontSize: 18,
      lineHeight: '130%',
      letterSpacing: -0.15,
      color: Colors.gray50(),
    };
  }

  static paragraph2({ bold = false, italic = false } = {}) {
    return {
      ...Theme.paragraph1({
        bold,
        italic,
      }),
      fontSize: 14,
    };
  }

  static button() {
    return {
      ...semiBold(),
      fontSize: 18,
      lineHeight: '130%',
      letterSpacing: -0.15,
      color: Colors.nearBlack(),
    };
  }

  static input({ placeholder = false } = {}) {
    return {
      ...regular(),
      fontSize: 16,
      lineHeight: '130%',
      letterSpacing: -0.15,
      color: placeholder ? Colors.gray50() : Colors.nearBlack(),
    };
  }

  static inputLabel() {
    return {
      ...regular(),
      fontSize: 14,
      lineHeight: '130%',
      letterSpacing: -0.15,
      color: Colors.gray50(),
    };
  }

  static captionTitle({ bold = false } = {}) {
    return {
      ...Theme.paragraph1({
        bold,
      }),
      color: Colors.darkPink(),
    };
  }

  static boldCaptionTitle() {
    return {
      ...Theme.captionTitle({
        bold: true,
      }),
    };
  }

  static caption({ bold = false } = {}) {
    return {
      ...Theme.paragraph1({
        bold,
      }),
      color: Colors.darkPink(),
    };
  }

  static captionBold() {
    return {
      ...Theme.caption({
        bold: true,
      }),
    };
  }

  static link({ bold = false } = {}) {
    return {
      ...Theme.paragraph1({
        bold,
      }),
      color: Colors.nearBlack(),
      cursor: 'pointer',
      textDecoration: 'underline',
      transition: 'color 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);',
      ':hover': {
        color: Colors.rootOrange(),
      },
    };
  }

  static linkRootOrange({ bold = false } = {}) {
    return {
      ...Theme.link({ bold }),
      whiteSpace: 'nowrap',
      textDecoration: 'underline',
      color: Colors.rootOrange(),
    };
  }

  static boldLink() {
    return {
      ...Theme.link({
        bold: true,
      }),
    };
  }

  static textLink() {
    return {
      color: Colors.rootOrange(),
      textDecoration: 'underline',
      cursor: 'pointer',
    };
  }

  static card() {
    return {
      borderRadius: 10,
      backgroundColor: Colors.white(),
      padding: 80,
      margin: 10,
      ...Shadows.cardShadow(),
    };
  }

  static cardTitle() {
    return {
      ...Theme.heading3(),
      color: Colors.rootOrange(),
    };
  }

  static cardHeading() {
    return {
      ...Theme.heading1(),
    };
  }

  static cardContent({ bold = false, black = false } = {}) {
    return {
      ...Theme.paragraph1({
        bold,
      }),
      ...black && {
        color: Colors.nearBlack(),
      },
    };
  }

  static roundedCorners() {
    return {
      borderRadius: 6,
    };
  }

  static listHeader() {
    return {
      ...Theme.heading4(),
    };
  }
}

export class IntentType {
  static PRIMARY = 'primary';
  static SECONDARY = 'secondary';
  static TERTIARY = 'tertiary';
  static WARN = 'warn';
  static INVALID = 'invalid';
  static VALID = 'valid';
  static NONE = null;
}
