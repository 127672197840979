class FunnelStage {
  constructor() {
    if (!FunnelStage.instance) {
      this._funnelStage = null;
    }
    FunnelStage.instance = this;

    return FunnelStage.instance;
  }

  setFunnelStage(funnelStage) {
    this._funnelStage = funnelStage ?? this._funnelStage;
  }

  getFunnelStage() {
    return this._funnelStage;
  }

  reset() {
    this._funnelStage = null;
  }
}

const instance = new FunnelStage();

export default instance;
