import Button from '@root/core/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default class LoaderButton extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    loaderButtonRef: PropTypes.any,
    loadingText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    styles: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]),
  }

  static defaultProps = {
    disabled: false,
  }

  _onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  _buttonText() {
    if (this.props.isLoading) {
      return this.props.loadingText;
    } else {
      return this.props.children;
    }
  }

  _isDisabled = () => {
    return this.props.disabled || this.props.isLoading;
  }

  render() {
    return (
      <Button
        css={this.props.styles}
        disabled={this._isDisabled()}
        onClick={this._onClick}
        ref={this.props.loaderButtonRef}
      >
        {this._buttonText()}
      </Button>
    );
  }
}
