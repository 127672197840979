import PropTypes from '@root/vendor/prop-types';
import React, { lazy } from '@root/vendor/react';
import Routes from '@root/renters.joinroot.com/src/routes/protected-routes';
import { Route, Switch } from '@root/core/src/components/route';

const PostBindEntry = lazy(() => import(/* webpackChunkName: "post-bind-entry" */ '@root/renters.joinroot.com/src/post-bind/components/entry'));
const ProfileEntry = lazy(() => import(/* webpackChunkName: "profile-entry" */ '@root/renters.joinroot.com/src/profile/components/entry'));
const QuoteEntry = lazy(() => import(/* webpackChunkName: "quote-entry" */ '@root/renters.joinroot.com/src/quote/components/entry'));

export default function RestrictedFlowRouter({ passProps }) {
  return (
    <Switch>
      <Route
        path={Routes.POST_BIND}
        render={() => <PostBindEntry passProps={passProps} />}
      />
      <Route
        path={Routes.PROFILE}
        render={() => <ProfileEntry passProps={passProps} />}
      />
      <Route
        path={Routes.QUOTE}
        render={() => <QuoteEntry passProps={passProps} />}
      />
    </Switch>
  );
}

RestrictedFlowRouter.propTypes = {
  passProps: PropTypes.object.isRequired,
};
