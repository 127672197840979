import PropTypes from '@root/vendor/prop-types';

export default function Col({
  as: RenderComponent = 'div', children, className = '', ...sizeProps
}) {
  const classNames = [];

  if (sizeProps.xs) {
    classNames.push(`col-${sizeProps.xs}`);
  }

  ['sm', 'md', 'lg', 'xl'].forEach((size) => {
    if (sizeProps[size]) {
      classNames.push(`col-${size}-${sizeProps[size]}`);
    }
  });

  return (
    <RenderComponent className={`${classNames.join(' ')} ${className}`.trim()}>
      {children}
    </RenderComponent>
  );
}

Col.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xl: PropTypes.number,
  xs: PropTypes.number,
};
