import Button from '@root/core/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function LinkButton({
  children, href = '#', rel, disabled = false, ...props
}) {
  return (
    <Button
      {...props}
      passProps={{
        href: disabled ? undefined : href,
        rel,
      }}
      tag={'a'}
    >
      {children}
    </Button>
  );
}

LinkButton.propTypes = {
  ...Button.propTypes,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  rel: PropTypes.string,
};
