import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { keyframes } from '@root/vendor/@emotion/core';

export default function CaretLoader() {
  return (
    <div
      aria-label={'Loading'}
      css={styles.container}
    >
      <div css={[styles.block, styles.firstBlock]} />
      <div css={[styles.block, styles.secondBlock]} />
      <div css={[styles.block, styles.thirdBlock]} />
    </div>
  );
}

const firstBlockAnimation = keyframes({
  '0%': {
    transform: 'translate3d(0px, 11.32px, 0px)',
  },
  '17.8%, 48%': {
    transform: 'translate3d(0px, 27px, 0px)',
  },
  '61.6%': {
    transform: 'translate3d(11.32px, 0px, 0px)',
  },
  '72.6%': {
    transform: 'translate3d(38.32px, 0px, 0px)',
  },
  '87.7%': {
    transform: 'translate3d(38.32px, 27px, 0px)',
  },
  '100%': {
    transform: 'translate3d(11.32px, 27px, 0px)',
  },
});

const secondBlockAnimation = keyframes({
  '0%': {
    transform: 'translate3d(0px, 11.32px, 0px)',
  },
  '17.8%, 48%': {
    transform: 'translate3d(0px, 0px, 0px)',
  },
  '61.6%': {
    transform: 'translate3d(11.32px, 0px, 0px)',
  },
  '72.6%': {
    transform: 'translate3d(38.32px, 0px, 0px)',
  },
  '87.7%': {
    transform: 'translate3d(38.32px, 27px, 0px)',
  },
  '100%': {
    transform: 'translate3d(11.32px, 27px, 0px)',
  },
});

const thirdBlockAnimation = keyframes({
  '0%': {
    transform: 'translate3d(0px, 11.32px, 0px)',
  },
  '17.8%, 48%': {
    transform: 'translate3d(27px, 0px, 0px)',
  },
  '61.6%, 72.6%': {
    transform: 'translate3d(38.32px, 0px, 0px)',
  },
  '87.7%': {
    transform: 'translate3d(38.32px, 27px, 0px)',
  },
  '100%': {
    transform: 'translate3d(11.32px, 27px, 0px)',
  },
});

const wrapperAnimation = keyframes({
  '0%, 36%': {
    transform: 'rotate(0deg)',
  },
  '58.9%, 100%': {
    transform: 'rotate(90deg)',
  },
});

const styles = StyleSheet.create({
  container: {
    height: 54,
    width: 54,
    position: 'relative',
    animation: `${wrapperAnimation} 1.2s linear infinite`,
  },
  block: {
    height: 28,
    width: 28,
    background: Colors.black(),
    position: 'absolute',
  },
  firstBlock: {
    animation: `${firstBlockAnimation} 1.2s ease-out infinite`,
  },
  secondBlock: {
    animation: `${secondBlockAnimation} 1.2s ease-out infinite`,
  },
  thirdBlock: {
    animation: `${thirdBlockAnimation} 1.2s ease-out infinite`,
  },
});
