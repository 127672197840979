import useNetworkRequestBase from '@root/core/src/hooks/use-network-request-base';
import { useCallback } from '@root/vendor/react';

export default function useNetworkRequest(networkRequestConfiguration, resultHandlerCallback) {
  return useNetworkRequestBase(networkRequestConfiguration, useCallback((result) => {
    if (result.isUnexpectedError() || result.isNetworkConnectivityError()) {
      throw result.error;
    }

    resultHandlerCallback(result);
  }, [resultHandlerCallback]));
}
