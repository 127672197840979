import AuthService from '@root/core/src/services/auth-service';
import UserSessionService from '@root/user-session/src/services/user-session-service';
import loginWithTokenConfiguration from '@root/auth/src/api/login-with-token/login-with-token-configuration';
import useAssociateTouchAttribution from '@root/attribution/src/hooks/use-associate-touch-attribution';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { useCallback, useEffect, useMemo, useState } from '@root/vendor/react';
import { useLocation } from '@root/vendor/react-router-dom';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

const botPattern = '(googlebot/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
const botRe = new RegExp(botPattern, 'i');

export default function useLoginWithQueryParam() {
  const location = useLocation();
  const [loginWithToken] = useImperativeNetworkRequest(loginWithTokenConfiguration);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const [associateTouchAttribution] = useAssociateTouchAttribution();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const rootId = useRootId();

  /**
   * @param {string} accessToken - can be null/undefined.
   * Reset local state so user is NOT authenticated if no accessToken
   */
  const processAccessToken = useCallback((accessToken) => {
    if (accessToken) {
      AuthService.setAccessToken(accessToken);

      setPhoneNumber(null);
      setIsAuthenticated(true);
      setIsValidating(false);

      UserSessionService.initializeSessionActivity();
      associateTouchAttribution({
        rootId,
      });
    } else {
      setPhoneNumber(null);
      setIsAuthenticated(false);
      setIsValidating(false);
    }
  }, [associateTouchAttribution, rootId]);

  /**
   * @param {object} loginRequestObject - requires token and optional otpToken
   */
  const loginWithEmailToken = useCallback(async (loginRequestObject) => {
    const result = await loginWithToken(loginRequestObject);

    if (result.isExpectedError()) {
      setIsValidating(false);
      setPhoneNumber(null);
      setIsAuthenticated(false);
      return;
    }

    if (result.data?.phoneNumber) {
      setPhoneNumber(result.data.phoneNumber);
      setIsValidating(false);
      return;
    }

    processAccessToken(result.data.accessToken);
  }, [loginWithToken, processAccessToken]);

  const emailLoginToken = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const loginToken = params.get('auth');

    return loginToken;
  }, [location.search]);
  const loginPayloadObject = { loginToken: emailLoginToken };

  useEffect(() => {
    const isBot = botRe.test(navigator.userAgent);

    if (isBot || !emailLoginToken) {
      setIsValidating(false);
      return;
    }

    setIsValidating(true);

    loginWithEmailToken({ loginToken: emailLoginToken });
  }, [loginWithEmailToken, emailLoginToken]);

  return {
    isQueryParamAuthenticated: isAuthenticated,
    isValidatingQueryParam: isValidating,
    loginPayloadObject,
    loginWithRequiredOtpCode: phoneNumber ? loginWithToken : null,
    phoneNumber,
    processAccessToken,
  };
}
