import AttributionService from '@root/attribution/src/services/attribution-service';
import { useEffect } from '@root/vendor/react';

export default function TrackVisitAttributionController() {
  useEffect(() => {
    AttributionService.trackVisit();
  }, []);

  return null;
}
