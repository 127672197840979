import Button from '@root/core/src/components/button';
import CSSTransition from '@root/vendor/react-transition-group/CSSTransition';
import GoogleMap from '@root/renters.joinroot.com/src/components/google-map';
import GoogleMapsService from '@root/core/src/services/google-maps-service';
import Header from '@root/core/src/components/header';
import LandingDisclaimer from '@root/renters.joinroot.com/src/landing/components/landing-disclaimer';
import Link from '@root/core/src/components/link';
import LoginRoutes from '@root/renters.joinroot.com/src/login/routes';
import PropTypes from '@root/vendor/prop-types';
import PublicRoutes from '@root/renters.joinroot.com/src/routes/public-routes';
import React, { useMemo, useState } from '@root/vendor/react';
import RentersAutocompleteAddressForm from '@root/renters.joinroot.com/src/components/renters-autocomplete-address-form';
import Responsive from '@root/core/src/utils/responsive';
import SceneLoader from '@root/core/src/components/scene-loader';
import createConfiguration from '@root/renters.joinroot.com/src/profile/api/create-profile-answer-set/configuration';
import getNewBusinessQuotingEligibility from '@root/renters.joinroot.com/src/api/get-new-business-quoting-eligibility/configuration';
import landing from '@root/renters.joinroot.com/src/assets/landing.jpg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useSignup from '@root/renters.joinroot.com/src/hooks/use-signup';
import { AnimationStyleSheet, Colors, Shadows, StyleSheet } from '@root/core/src/utils/styles';
import { keyframes } from '@root/vendor/@emotion/core';
import { useHistory } from '@root/core/src/components/route';
import { withGoogleMapsApi } from '@root/core/src/components/with-google-maps-api';

export function Landing({ google, onLogin }) {
  const history = useHistory();
  const googleService = useMemo(() => new GoogleMapsService(google), [google]);
  const { signup } = useSignup();
  const [getEligibility] = useImperativeNetworkRequest(getNewBusinessQuotingEligibility);
  const [createProfileAnswerSet] = useImperativeNetworkRequest(createConfiguration);
  const [focusedInput, setFocusedInput] = useState(false);
  const [latLng, setLatLng] = useState();
  const { trackClick } = useAnalytics('ADDRESS_SCENE');

  const handlePredictionSelect = (address) => {
    setLatLng({
      lat: address.lat,
      lng: address.lng,
    });
  };

  const handleSignInClick = () => {
    trackClick('SIGN_IN');
    routeToEmailLogin();
  };

  const handleAlreadyStartedClick = () => {
    trackClick('ALREADY_STARTED');
    routeToEmailLogin();
  };

  const routeToEmailLogin = () => history.push(LoginRoutes.EMAIL_LOGIN);

  const handleSubmit = async (address) => {
    const { data: { isEligible, reason } } = await getEligibility({
      market: address.state,
      zip: address.zip,
    });

    if (isEligible) {
      await signup();
      await createProfileAnswerSet({
        answerSet: {
          ...address.serializeForSubmission(),
        },
      });
      onLogin();
    } else {
      history.push(PublicRoutes.INELIGIBLE, {
        market: address.state,
        reason,
      });
    }
  };

  const SignIn = Responsive.matches(Responsive.QUERIES.lessThanSm)
    ? Link
    : Button;

  const subText = Responsive.matches(Responsive.QUERIES.lessThanSm)
    ? 'Enter your address to start your quote.'
    : 'Enter your address to start your renters insurance quote';

  const inputStyle = focusedInput
    ? {}
    : styles.inputWrapper;

  const mapAnimation = latLng
    ? styles.mapAnimation
    : {};

  return (
    <div css={styles.page}>
      <Header
        css={styles.header}
        showProgressBar={false}
      />
      <CSSTransition
        classNames={fadeOutAnimation}
        in={!focusedInput}
        mountOnEnter={true}
        timeout={200}
        unmountOnExit={true}
      >
        <SignIn
          css={styles.signIn}
          onClick={handleSignInClick}
          styles={styles.signIn}
        >
          {'Sign in'}
        </SignIn>
      </CSSTransition>
      {focusedInput &&
      <div css={[styles.mapContainer, mapAnimation]}>
        {!!latLng &&
        <GoogleMap
          latLng={latLng}
          mapsService={googleService}
          options={{
            zoom: 18,
            disableDefaultUI: true,
          }}
          style={styles.map}
        />
        }
      </div>
      }
      <div css={styles.topContainer}>
        {!focusedInput &&
          <div css={styles.headingContainer}>
            <h1 css={styles.heading}>{'Root insurance. For all your stuff.'}</h1>
            <p css={styles.subText}>{subText}</p>
          </div>
        }
        <div css={styles.formContainer}>
          <CSSTransition
            classNames={fadeInAnimation}
            in={focusedInput}
            timeout={200}
            unmountOnExit={true}
          >
            <h1 css={[styles.heading, styles.addressHeading]}>{'What\'s your address?'}</h1>
          </CSSTransition>
          <RentersAutocompleteAddressForm
            autocompleteInputWrapperStyle={inputStyle}
            googleService={googleService}
            onAutocompleteFocus={() => setFocusedInput(true)}
            onAutocompletePredictionSelect={handlePredictionSelect}
            onSubmit={handleSubmit}
            tipText={'We’ll look through public records to get some information about your address & the structure of your home.'}
          />
        </div>
      </div>
      <CSSTransition
        classNames={imageOutAnimation}
        in={!focusedInput}
        mountOnEnter={true}
        timeout={200}
        unmountOnExit={true}
      >
        <div css={styles.imageContainer}>
          {!focusedInput &&
          <>
            <Link
              css={styles.link}
              onClick={handleAlreadyStartedClick}
            >
              {'Already started your quote?'}
            </Link>
            <LandingDisclaimer />
          </>
          }
        </div>
      </CSSTransition>
    </div>
  );
}

Landing.propTypes = {
  google: PropTypes.object.isRequired,
  onLogin: PropTypes.func.isRequired,
};

export default withGoogleMapsApi(Landing, SceneLoader);

const imageOutAnimation = AnimationStyleSheet.createClassNames({
  exit: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  exitActive: {
    opacity: 0,
    transition: 'all 150ms linear',
    transform: 'translateY(250px)',
  },
});

const fadeOutAnimation = AnimationStyleSheet.createClassNames({
  exit: {
    opacity: 1,
  },
  exitActive: {
    opacity: 0,
    transition: 'all 200ms linear 50ms',
  },
});

const fadeInAnimation = AnimationStyleSheet.createClassNames({
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
    transition: 'all 200ms linear 100ms',
  },
});

const growDown = keyframes({
  '0%': {
    height: 0,
  },
  '100%': {
    height: 150,
  },
});

const styles = StyleSheet.create({
  addressHeading: {
    textAlign: 'left',
    lineHeight: '36px',
    marginBottom: 36,
    marginTop: 12,
    ...Responsive.sm({
      marginTop: 0,
    }),
  },
  formContainer: {
    margin: 'auto',
    position: 'relative',
    width: '87%',
    zIndex: 1,
    ...Responsive.md({
      width: '66%',
    }),
    ...Responsive.lg({
      width: '30%',
    }),
  },
  heading: {
    fontSize: '30px',
    lineHeight: '32px',
    textAlign: 'center',
    ...Responsive.lg({
      fontSize: '36px',
      lineHeight: '36px',
    }),
  },
  headingContainer: {
    margin: '96px auto 36px auto',
    position: 'relative',
    width: '87%',
    zIndex: 1,
    ...Responsive.md({
      width: '66%',
    }),
    ...Responsive.lg({
      width: '30%',
    }),
  },
  imageContainer: {
    flex: 'auto',
    backgroundImage: `url(${landing})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  inputWrapper: {
    ...Shadows.cardShadow(),
  },
  link: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    marginTop: 72,
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'underline',
    width: '100%',
  },
  mapContainer: {
    height: 0,
    marginTop: 60,
    marginBottom: 36,
    position: 'relative',
    width: '100%',
    ...Responsive.sm({
      height: '25vh',
    }),
  },
  map: {
    height: '100%',
    width: '100%',
  },
  mapAnimation: {
    animation: `${growDown} 100ms ease-in 1`,
    animationDelay: '100ms',
    animationFillMode: 'forwards',
    ...Responsive.sm({
      animation: 'none',
    }),
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  signIn: {
    zIndex: 2,
    position: 'fixed',
    top: '1%',
    right: '1%',
    height: 48,
    marginTop: 0,
    textDecoration: 'underline',
    width: 72,
    ...Responsive.sm({
      width: 118,
      textDecoration: 'none',
    }),
  },
  subText: {
    margin: 0,
    marginTop: 24,
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.15px',
    color: Colors.gray50(),
  },
  topContainer: {
    marginBottom: -30,
  },
});
