import { Colors, Shadows } from '@root/core/src/utils/styles';
import { opacityOverlayOnState } from '@root/core/src/utils/opacity-overlay';

const BUTTON_BORDER_WIDTH = 2;

export const BUTTON_VARIANT_PRIMARY = 'primary';
export const BUTTON_VARIANT_SECONDARY = 'secondary';
export const BUTTON_VARIANT_SECONDARY_ORANGE = 'secondary_orange';

export const BUTTON_VARIANTS = [
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_SECONDARY,
  BUTTON_VARIANT_SECONDARY_ORANGE,
];

export const BUTTON_VARIANT_STYLES = {
  [BUTTON_VARIANT_PRIMARY]: {
    ...Shadows.onHoverStateShadow(),
    backgroundColor: Colors.rootOrange(),
    color: Colors.white(),
    border: 0,
    svg: {
      fill: Colors.white(),
    },
    ...Shadows.onFocusStateShadow(),
    ':active': {
      ...Shadows.none(),
    },
    ':disabled': {
      background: Colors.gray10(),
    },
  },
  [BUTTON_VARIANT_SECONDARY]: {
    backgroundColor: Colors.white(),
    color: Colors.nearBlack(),
    border: `${BUTTON_BORDER_WIDTH}px solid ${Colors.rootOrange()}`,
    svg: {
      fill: Colors.rootOrange(),
    },
    ':hover': {
      ...Shadows.none(),
      borderColor: Colors.nearBlack(),
    },
    ...Shadows.onFocusStateShadow({
      borderColor: Colors.rootOrange(),
    }),
    ...opacityOverlayOnState({
      stateStyles: {
        ...Shadows.none(),
        borderColor: Colors.rootOrange(),
      },
      borderWidth: BUTTON_BORDER_WIDTH,
    }),
    ':disabled': {
      background: Colors.white(),
      borderColor: Colors.gray40(),
    },
  },
  [BUTTON_VARIANT_SECONDARY_ORANGE]: {
    backgroundColor: Colors.white(),
    color: Colors.rootOrange(),
    border: 'none',
    borderTop: `1px solid ${Colors.gray30()}`,
    borderRadius: '0',
    svg: {
      stroke: Colors.rootOrange(),
      width: 22,
      strokeWidth: 3,
    },
    ':disabled': {
      background: Colors.white(),
      borderColor: Colors.gray40(),
    },
  },
};
