import { UAParser } from 'ua-parser-js';

const MAXIMUM_PHONE_WIDTH = 600;

export function isMobileBrowser() {
  const parser = new UAParser();
  if (parser.getDevice().type !== 'mobile') {
    return false;
  }

  const smallestMeasurement = Math.min(window.innerWidth, window.innerHeight);

  return smallestMeasurement <= MAXIMUM_PHONE_WIDTH;
}

export function isDesktopBrowser() {
  const parser = new UAParser();

  if (parser.getDevice().type !== undefined) {
    return false;
  }

  return true;
}

export function isIos() {
  const parser = new UAParser();
  return parser.getOS().name === 'iOS';
}

export function isAndroid() {
  const parser = new UAParser();
  return parser.getOS().name === 'Android';
}
