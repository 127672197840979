import { lazy } from '@root/vendor/react';
import { loadGoogleMapsApiClient } from '@root/core/src/api/google-maps';

export default lazy(async () => {
  const google = await loadGoogleMapsApiClient();

  // lazy assumes the promise resolves to an import, and grabs the default off of it
  // this workaround works for now, but may break in a future version of React.
  return {
    default: (props) => props.children(google),
  };
});
