import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function createTouchAttributionConfiguration({
  sourcePathUrl, referrerUrl, rootId, mixpanelDistinctId,
}) {
  return new NetworkRequestConfiguration({
    endpoint: 'attributions/touch_attributions',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      sourcePathUrl,
      referrerUrl,
      rootId,
      mixpanelDistinctId,
    },
  });
}

