import CookieStorageService from '@root/core/src/services/cookie-storage-service';

const EVENTS = {
  HISTORY_CHANGE: 'HISTORY_CHANGE',
  USER_ID_UPDATED: 'USER_ID_UPDATED',
  USER_AUTHENTICATED: 'USER_AUTHENTICATED',
  DISTINCT_ID_SET: 'DISTINCT_ID_SET',
};

export default class TrackingService {
  static eventQueue = [];
  static iframe = undefined;
  static loadedPoller = undefined;

  static fireUserIdUpdatedEvent(userId) {
    this._persistUserIdForWhiteOps(userId);
    CookieStorageService.set('root_whiteops_user_id', userId, {
      expires: 7,
    });
    this.fireEvent(EVENTS.USER_ID_UPDATED, {
      userId,
    });
  }

  static fireUserAuthenticatedEvent(userId) {
    this._persistUserIdForWhiteOps(userId);
    this.fireEvent(EVENTS.USER_AUTHENTICATED, {
      userId,
    });
  }

  static fireHistoryChangeEvent(location) {
    this.fireEvent(EVENTS.HISTORY_CHANGE, {
      location,
    });
  }

  static fireDistinctIdSetEvent(distinctId) {
    this.fireEvent(EVENTS.DISTINCT_ID_SET, {
      distinctId,
    });
  }

  static addObject(data = {}) {
    this._sendToIframe(data);
  }

  static setData(key, value) {
    this._sendToIframe({
      [key]: value,
    });
  }

  static fireEvent(eventName, data = {}) {
    this._sendToIframe({
      event: eventName,
      ...data,
    });
  }

  static _persistUserIdForWhiteOps(userId) {
    CookieStorageService.set('root_whiteops_user_id', userId, {
      expires: 7,
    });
  }

  static _sendToIframe(data) {
    if (this.iframe) {
      this.iframe.contentWindow.postMessage(data, '*');
    } else {
      this.eventQueue.push(data);

      if (!this.loadedPoller) {
        this._setupLoadedPoller();
      }
    }
  }

  static _setupLoadedPoller() {
    if (this.loadedPoller) { return; }
    const iframe = document.getElementById('root-tracking-iframe');
    if (!iframe) { return; }

    this.loadedPoller = window.setInterval(() => {
      if (!this._isLoaded()) { return; }

      this.iframe = iframe;
      window.clearInterval(this.loadedPoller);
      this._triggerEventQueue();
    }, 1000);
  }

  static _isLoaded() {
    return !!window.__trackingIframeLoaded;
  }

  static _triggerEventQueue() {
    this.eventQueue.forEach((eventData) => this._sendToIframe(eventData));
    this.eventQueue = [];
  }
}
