import Recase from '@root/vendor/recase';
import environment from '@root/core/src/utils/environment';
import { RootError } from '@root-common/root-errors';

const recase = Recase.create({});

export default class NetworkRequestConfiguration {
  static Methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
  };

  constructor({
    url,
    endpointBase = 'web_api',
    endpoint,
    method,
    queryParameters = {},
    bodyParameters = {},
    successCodes = [200],
    errorCodes = [],
  }) {
    if (!Object.keys(NetworkRequestConfiguration.Methods).includes(method)) {
      throw new RootError({
        message: 'UnknownMethodError',
        name: 'NetworkRequestConfigurationError',
      });
    }

    this.endpoint = endpoint;
    this.url = url || getURL(endpointBase, endpoint, queryParameters);
    this.body = getBody(bodyParameters);
    this.method = method;
    this.successCodes = successCodes;
    this.errorCodes = errorCodes;
    // eslint-disable-next-line root/deprecate-constructor
    this.stack = new Error('error').stack;
  }

  headers(additionalHeaders) {
    return new Headers({
      Accept: 'application/json',
      'Appetize-Simulation': false,
      'Client-Api-Version': environment.apiVersion,
      'Client-App-Name': 'Root Web',
      'Client-Device': 'Browser',
      'Client-Framework': 'React',
      'Content-Type': 'application/json',
      ...additionalHeaders,
    });
  }
}

function getURL(endpointBase, endpoint, queryParameters) {
  if (!endpoint) {
    throw new RootError({
      message: 'NoEndpointError',
      name: 'NetworkRequestConfigurationError',
    });
  }

  const { apiBaseUrl } = environment;
  const url = `${apiBaseUrl}/${endpointBase}/${endpoint}`;

  const searchParams = new URLSearchParams();
  const recasedQueryParameters = recase.snakeCopy(queryParameters);
  const queryKeys = Object.keys(recasedQueryParameters);
  queryKeys.forEach((key) => {
    const queryValue = recasedQueryParameters[key];
    if (Array.isArray(queryValue)) {
      queryValue.forEach((value) => searchParams.append(`${key}[]`, value));
    } else if (typeof queryValue === 'object' && queryValue && !(queryValue instanceof Date)) {
      Object.entries(queryValue).forEach(([nestedKey, nestedValue]) => {
        if (typeof nestedValue === 'object') {
          throw new RootError({
            message: 'UnableToParseNestedObjects',
            name: 'NetworkRequestConfigurationError',
          });
        }
        searchParams.append(`${key}[${nestedKey}]`, nestedValue);
      });
    } else {
      searchParams.append(key, queryValue);
    }
  });

  return queryKeys.length > 0 ? `${url}?${searchParams.toString()}` : url;
}

function getBody(body) {
  if (Object.keys(body).length === 0) {
    return;
  }

  return JSON.stringify(recase.snakeCopy(body));
}
