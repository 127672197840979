import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function emailReturningUserConfiguration({ email }) {
  return new NetworkRequestConfiguration({
    endpoint: 'renters/login',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      email,
    },
    successCodes: [201, 204],
  });
}
